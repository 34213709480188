import React, { Component } from "react";
import MaxLiProduction from "./MaxLiProduction";

class Demo extends Component {
  render() {
    return (
      <div className="container-fluid my-4">
        <h3 className="text-center">
          Want to try? We have created accounts for you!
        </h3>
        <table
          className="text-center table table-responsive-sm table-striped
        table-bordered table-hover table-condensed"
        >
          <tbody>
            <tr>
              <th>name (web, mobile app)</th>
              <th>username</th>
              <th>hint (REAL password!)</th>
            </tr>
            <tr>
              <td>onlinetraces.com</td>

              <td>ot1</td>
              <td>OnlineTraces2Rock!</td>
            </tr>
          </tbody>
        </table>

        <MaxLiProduction />
      </div>
    );
  }
}

export default Demo;
