/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:3c9cb6c6-4996-435e-838c-41021804dee1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_PENG6l647",
    "aws_user_pools_web_client_id": "6hd89lki2cu0k27fefo5e3cj7l",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://pa7tnx5dv5hlzoxos7apykkske.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "shareWiselyRESTAPI",
            "endpoint": "https://x7yaoa17lf.execute-api.us-east-1.amazonaws.com/swone",
            "region": "us-east-1"
        }
    ],
    "aws_content_delivery_bucket": "sharewisely001-20200418102020-hostingbucket-swone",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d2917emvwucgdz.cloudfront.net"
};


export default awsmobile;
