import React, { Component } from "react";
import { Auth, API, graphqlOperation } from "aws-amplify";
import "../index.css";

import { createMessaging } from "./SomeConstants";
import { listMessagings } from "./SomeConstants";

import MaxLiProduction from "./MaxLiProduction";

class Messaging extends Component {
  constructor(props) {
    super(props);
    this.state = {
      receiverUserName: "",
      content2BeSent: "",
      messages2Me: null,
      messagesFromeMe: null,
      displayFlashMsg: false,
      placeHolderContent: "What is in your mind?",
      placeHolderReceiver: "Receiver's Username",
      hasError: false,
      errorMessage: "",
    };

    this.handleReceiverUserNameChange = this.handleReceiverUserNameChange.bind(
      this
    );
    this.handleContent2BeSentChange = this.handleContent2BeSentChange.bind(
      this
    );

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  async componentDidMount() {
    const currentUser = await Auth.currentAuthenticatedUser();
    const loggedUserName = currentUser.username;

    const messagingFilter = {
      filter: {
        to: {
          eq: loggedUserName,
        },
      },
      limit: 99999,
    };

    const {
      data: {
        listMessagings: { items: aList },
      },
    } = await API.graphql(graphqlOperation(listMessagings, messagingFilter));
    console.log("alist is: ", aList);

    const data = aList.map((item, i) => (
      <tr key={i + 70}>
        <td className="text-center" key={i + 71}>
          <button
            className="btn btn-primary"
            onClick={(e) => this.handleAssignmentClick(e, item.from)}
          >
            {item.from}
          </button>
        </td>
        <td className="text-center" key={i + 72}>
          {item.content}
        </td>
      </tr>
    ));

    this.setState({ messages2Me: data });

    await this.createMessagesFromMe(loggedUserName);

    const search = this.props.location.search; // could be '?q=value123'
    const params = new URLSearchParams(search);
    const q = params.get("q"); // value123
    console.log("q is:", q);
    if (q !== "") {
      this.setState({ receiverUserName: q });
    }
  }

  async createMessagesFromMe(loggedUserName) {
    const aFilter = {
      filter: {
        from: {
          eq: loggedUserName,
        },
      },
      limit: 99999,
    };

    const {
      data: {
        listMessagings: { items: aList },
      },
    } = await API.graphql(graphqlOperation(listMessagings, aFilter));
    console.log("alist is: ", aList);

    const data = aList.map((item, i) => (
      <tr key={i + 170}>
        <td className="text-center" key={i + 171}>
          <button
            className="btn btn-primary"
            onClick={(e) => this.handleAssignmentClick(e, item.to)}
          >
            {item.from}
          </button>
        </td>
        <td className="text-center" key={i + 172}>
          {item.content}
        </td>
      </tr>
    ));

    this.setState({ messagesFromMe: data });
  }

  handleAssignmentClick(event, name) {
    event.preventDefault();
    event.stopPropagation();

    this.setState({ receiverUserName: name });
  }

  handleReceiverUserNameChange(event) {
    this.setState({ receiverUserName: event.target.value });
  }

  handleContent2BeSentChange(event) {
    this.setState({ content2BeSent: event.target.value });
  }

  async handleFormSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    const currentUser = await Auth.currentAuthenticatedUser();
    const loggedUserName = currentUser.username;

    const thisMoment = new Date();

    let receiverUserName = this.state.receiverUserName;
    let content2BeSent = this.state.content2BeSent;

    if (receiverUserName !== "" && content2BeSent !== "") {
      const obj = {
        to: receiverUserName,
        from: loggedUserName,
        DT_created: thisMoment.toISOString(),
        is_deleted: false,
        content: content2BeSent,
      };
      await API.graphql(graphqlOperation(createMessaging, obj));

      this.setState({ hasError: false });
      this.setState({ displayFlashMsg: true });

      setTimeout(() => {
        this.setState({ displayFlashMsg: false });
      }, 1000);
      setTimeout(() => {
        this.setState({ receiverUserName: "" });
      }, 1000);
      setTimeout(() => {
        this.setState({ content2BeSent: "" });
      }, 1000);

      document.getElementById("MessagingInputSubmitID").blur();
    } else {
      this.setState({ hasError: true });
      this.setState({
        errorMessage: "Both fields must have value",
      });
      document.getElementById("MessagingInputSubmitID").blur();

      this.setState({ receiverUserName: "" });
      this.setState({ content2BeSent: "" });
    }
  }

  render() {
    return (
      <div className="container-fluid my-4">
        <h2>Messaging</h2>

        {this.state.hasError ? (
          <p className="color_red">
            Error Found: &nbsp;
            {this.state.errorMessage}
          </p>
        ) : null}

        <form id="formMessaging" onSubmit={this.handleFormSubmit}>
          <div className="form-group">
            <p className="my-4">Send a message to another user</p>
            <div className="mb-1">
              <label htmlFor="receiverUserName" className="text-left">
                Receiver's Username:
              </label>

              <input
                className="form-control"
                id="receiverUserName"
                size="30"
                placeholder={this.state.placeHolderReceiver}
                value={this.state.receiverUserName}
                onChange={this.handleReceiverUserNameChange}
              />
            </div>
            <label htmlFor="give">Content:</label>
            <textarea
              className="form-control"
              id="give"
              placeholder={this.state.placeHolderContent}
              rows="4"
              value={this.state.content2BeSent}
              onChange={this.handleContent2BeSentChange}
            />
          </div>
          <div className="mb-1 mt-1">
            <input
              id="MessagingInputSubmitID"
              className="btn btn-primary mt-2"
              type="submit"
              value="Submit"
            />

            {this.state.displayFlashMsg ? (
              <span className="color_red mx-1 my-1">Message Sent</span>
            ) : null}
          </div>
          <div className="my-2">
            <p>Messages From Others</p>
            <table
              className="text-center table table-responsive-sm table-striped
        table-bordered table-hover table-condensed"
            >
              <thead>
                <tr>
                  <th>Message sender (username)</th>
                  <th>Content</th>
                </tr>
              </thead>
              <tbody>{this.state.messages2Me}</tbody>
            </table>
          </div>

          <div className="my-2">
            <p>My own messages</p>
            <table
              className="text-center table table-responsive-sm table-striped
        table-bordered table-hover table-condensed"
            >
              <thead>
                <tr>
                  <th>Message sender (username)</th>
                  <th>Content</th>
                </tr>
              </thead>
              <tbody>{this.state.messages2Me}</tbody>
            </table>
          </div>
        </form>
        <MaxLiProduction />
      </div>
    );
  }
}
export default Messaging;
