import React, { Component } from "react";
import { NavLink as Link } from "react-router-dom";
import { API, graphqlOperation, Auth } from "aws-amplify";
import "../index.css";

import { createAccessPoint, listAccessPointsWithAName } from "./SomeConstants";

class CreateAP extends Component {
  constructor(props) {
    super(props);
    const thisMoment = new Date();
    this.state = {
      name: "",
      purpose: "",
      creationDT: thisMoment.toISOString(),
      modificationDT: thisMoment.toISOString(),
      hasError: false,
      errorMessage: " "
    };
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handlePurposeChange = this.handlePurposeChange.bind(this);

    this.handleCreationDTChange = this.handleCreationDTOnChange.bind(this);
    this.handleModificationDTChange = this.handleModificationDTChange.bind(
      this
    );

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleNameChange(event) {
    this.setState({ name: event.target.value });
  }

  handlePurposeChange(event) {
    this.setState({ purpose: event.target.value });
  }

  handleCreationDTOnChange(event) {
    this.setState({ creationDT: event.target.value });
  }

  handleModificationDTChange(event) {
    this.setState({ modificationDT: event.target.value });
  }

  async handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.state.name.trim() === "") {
      this.setState({
        hasError: true,
        errorMessage: "The name must have visible characters."
      });
    } else {
      const user = await Auth.currentAuthenticatedUser();
      const userName = user.username;

      const result = await API.graphql(
        graphqlOperation(listAccessPointsWithAName, {
          name: this.state.name,
          userNameOfTheOwner: userName
        })
      );

      // if it is true, the name has been taken. Error message
      if (result.data.listAccessPoints.items[0]) {
        this.setState({
          hasError: true,
          errorMessage: "The name has taken. Please type in a different name."
        });
      } else {
        /* AWS DynamoDB does NOT allow empty strings in database */
        /*  As of 2019-04-11. The situation may change int the future */
        /* as a result, we need to change it to a string with a single */
        /* blank space inside it */

        let nameNotEmpty = this.state.name;
        if (nameNotEmpty === "") {
          nameNotEmpty = " ";
        }

        let purposeNotEmpty = this.state.purpose;
        if (purposeNotEmpty === "") {
          purposeNotEmpty = " ";
        }

        const obj = {
          name: nameNotEmpty,
          purpose: purposeNotEmpty,
          creationDT: this.state.creationDT,
          modificationDT: this.state.modificationDT,
          userNameOfTheOwner: userName
        };
        console.log("obj is: ", obj);
        await API.graphql(graphqlOperation(createAccessPoint, obj));
        console.log("done with create AP. Into a database.");
        this.props.history.push("/accesspoint");
      }
    }
  }

  render() {
    return (
      <div className="container-fluid my-4">
        {this.state.hasError ? (
          <p className="color_red">
            Error Found: &nbsp;
            {this.state.errorMessage}
          </p>
        ) : null}

        <form onSubmit={this.handleSubmit}>
          <div className="form-group">
            <div className="my-2">
              <label htmlFor="id_name">
                AP (Access Point. Always public) Name:
              </label>
              <input
                className="form-control"
                id="id_name"
                maxLength="254"
                name="name"
                type="text"
                size="30"
                required
                value={this.state.name}
                onChange={this.handleNameChange}
              />
            </div>

            <div className="my-2">
              <label htmlFor="id_purpose">Purpose:</label>
              <input
                className="form-control"
                id="id_purpose"
                maxLength="255"
                name="purpose"
                type="text"
                size="30"
                value={this.state.purpose}
                onChange={this.handlePurposeChange}
              />
            </div>

            <div className="my-2">
              <label htmlFor="id_creationDT">
                Created On (Format: YYYY-MM-DD HH:MM:SS):
              </label>
              <input
                className="form-control"
                id="id_creationDT"
                name="creationDT"
                type="text"
                size="30"
                value={this.state.creationDT}
                onChange={this.handleCreationDTOnChange}
              />
            </div>
            <div className="my-2">
              <label htmlFor="id_modificationDT">
                Modified On (Format: YYYY-MM-DD HH:MM:SS):
              </label>
              <input
                className="form-control"
                id="id_modificationDT"
                name="modificationDT"
                type="text"
                size="30"
                value={this.state.modificationDT}
                onChange={this.handleModificationDTChange}
              />
            </div>

            <div className="my-2">
              <input className="btn btn-primary" type="submit" value="Save" />
            </div>
          </div>
        </form>

        <Link className="btn btn-primary my-2" to="/accesspoint">
          Go back
        </Link>
      </div>
    );
  }
}

export default CreateAP;
