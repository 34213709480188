import React, { Component } from "react";

class MaxLiProduction extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="text-center mt-4">
          <h6>A Max Li Production</h6>
          <h6>
            Share Wisely &copy;
            {new Date().getFullYear()}
          </h6>
        </div>
      </React.Fragment>
    );
  }
}

export default MaxLiProduction;
