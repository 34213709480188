import React, { Component } from "react";
import { NavLink as Link } from "react-router-dom";
import { getAccessPoint } from "./SomeConstants";
import { API, graphqlOperation } from "aws-amplify";

class ShowAPDetails extends Component {
  async getItAndSetState(passedID) {
    const result = await API.graphql(
      graphqlOperation(getAccessPoint, { id: passedID })
    );
    console.log("result immediately after await is: ", result);

    const singleRow = result.data.getAccessPoint;
    console.log("singleRow is: ", singleRow);
    this.setState({ singleRow: singleRow });
  }

  constructor(props) {
    super(props);

    this.state = {
      singleRow: {},
    };

    this.getItAndSetState(props.match.params.id);
  }

  handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  render() {
    return (
      <React.Fragment>
        <form onSubmit={this.handleSubmit}>
          <div className="form-group">
            <div className="my-2">
              <h4>Note: Information is read ONLY. Cannot be changed.</h4>
            </div>
            <div>
              <label htmlFor="id_website">Name: &nbsp;</label>
              <input
                className="form-control"
                id="id_website"
                maxLength="254"
                size="30"
                name="website"
                type="text"
                value={this.state.singleRow.name}
              />
            </div>
            <div className="my-2">
              <label htmlFor="id_purpose">Purpose:</label>
              <input
                className="form-control"
                id="id_purpose"
                maxLength="255"
                size="30"
                name="purpose"
                type="text"
                value={this.state.singleRow.purpose}
              />
            </div>
            <div className="my-2">
              <label htmlFor="id_creationDT">CreationDT:</label>
              <input
                className="form-control"
                id="id_creationDT"
                maxLength="255"
                size="30"
                name="creationDT"
                type="text"
                value={this.state.singleRow.creationDT}
              />
            </div>

            <div className="my-2">
              <label htmlFor="id_modificationDT">ModificationDT:</label>
              <input
                className="form-control"
                id="id_modificationDT"
                maxLength="255"
                size="30"
                name="modificationDT"
                type="text"
                value={this.state.singleRow.modificationDT}
              />
            </div>
          </div>
        </form>

        <Link className="btn btn-primary my-2" to="/accesspoint">
          Go Back
        </Link>
      </React.Fragment>
    );
  }
}

export default ShowAPDetails;
