import React, { Component } from "react";

import { Auth } from "aws-amplify";

import { Link } from "react-router-dom";

import LoaderButton from "./LoaderButton";
// import "./Settings.css";

export default class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userName: "",
      email: "",
    };
  }

  async componentDidMount() {
    const user = await Auth.currentAuthenticatedUser();

    const userName = user.username;

    const email = user.attributes.email;
    this.setState({ userName: userName });

    this.setState({ email: email });
  }

  render() {
    return (
      <div className="container-fluid my-4">
        <div className="text-center ">
          Current Username is: {this.state.userName}
        </div>
        <div className="text-center ">Current Email is: {this.state.email}</div>

        <Link to="/settings/email">
          <LoaderButton
            className="btn btn-default  my-2"
            block
            text="Change Email"
          />
        </Link>
        <Link to="/settings/password">
          <LoaderButton
            className="btn btn-default  my-2"
            block
            text="Change Password"
          />
        </Link>
      </div>
    );
  }
}
