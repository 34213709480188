import React, { Component } from "react";

import { Auth, API, graphqlOperation } from "aws-amplify";
import "../index.css";

import { createGiveAndTake } from "./SomeConstants";
import { listGiveAndTakes } from "./SomeConstants";
import { deleteGiveAndTake } from "./SomeConstants";
import MaxLiProduction from "./MaxLiProduction";

class Market extends Component {
  constructor(props) {
    super(props);
    this.state = {
      give: "",
      take: "",
      sharingRequest: "",
      communityMessages: null,
      myOwnMessages: null,
      placeHolder: "What is in your mind?",
      hasError: false,
      errorMessage: "",
    };
    this.handleGiveChange = this.handleGiveChange.bind(this);
    this.handleTakeChange = this.handleTakeChange.bind(this);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  async componentDidMount() {
    // need to set sharingRequest based on a crucial
    // condition

    const currentUser = await Auth.currentAuthenticatedUser();
    const loggedUserName = currentUser.username;

    let d = new Date();
    // we need to constantly monitor this magic number
    d.setDate(d.getDate() - 60);

    let fullYear = d.getFullYear();
    let fullMonth = d.getMonth() + 1;
    let mm = fullMonth >= 10 ? "" + fullMonth : "0" + fullMonth;

    let fullDay = d.getDate();
    let dd = fullDay >= 10 ? "" + fullDay : "0" + fullDay;
    const aFewDaysAgo = fullYear + "-" + mm + "-" + dd;

    const sharingRequestFilter = {
      filter: {
        owner: {
          ne: loggedUserName,
        },
        DT_created: {
          gt: aFewDaysAgo,
        },
      },
      limit: 99999,
    };

    const {
      data: {
        listGiveAndTakes: { items: aList },
      },
    } = await API.graphql(
      graphqlOperation(listGiveAndTakes, sharingRequestFilter)
    );
    console.log("alist is: ", aList);

    const data = aList.map((item, i) => (
      <tr key={i + 90}>
        <td key={i + 91}>
          <a href={"/#/messaging?q=" + item.owner}>{item.owner}</a>
        </td>
        <td key={i + 92}>{item.give}</td>
        <td key={i + 93}>{item.take}</td>
      </tr>
    ));

    this.setState({ communityMessages: data });

    await this.createMyOwnMessages(loggedUserName);
  }

  async createMyOwnMessages(loggedUserName) {
    const myOwnMessagesFilter = {
      filter: {
        owner: {
          eq: loggedUserName,
        },
        DT_created: {
          gt: "2019-01-01",
        },
      },
      limit: 99999,
    };
    const {
      data: {
        listGiveAndTakes: { items: myOwnMessagesList },
      },
    } = await API.graphql(
      graphqlOperation(listGiveAndTakes, myOwnMessagesFilter)
    );
    const myOwnMessagesData = myOwnMessagesList.map((item, i) => (
      <tr key={i + 190}>
        <td key={i + 191}>
          <button
            className="btn btn-primary mx-1 my-1"
            title="Work In Progress"
          >
            Update
          </button>
          <button
            className="btn btn-danger mx-1 my-1"
            onClick={() => {
              if (
                window.confirm("Do you REALLY want to delete your own message?")
              ) {
                /* very important. Must create a const, then call it */
                /* Otherwise, it will NOT work */
                const removeToCollection = this.handleDelete.bind(
                  this,
                  item.id,
                  loggedUserName
                );
                removeToCollection();
              }
            }}
          >
            Delete
          </button>
        </td>{" "}
        <td key={i + 192}>{item.give}</td>
        <td key={i + 193}>{item.take}</td>
      </tr>
    ));
    this.setState({ myOwnMessages: myOwnMessagesData });
  }

  handleGiveChange(event) {
    this.setState({ give: event.target.value });
  }

  handleTakeChange(event) {
    this.setState({ take: event.target.value });
  }

  async handleDelete(id, loggedUserName) {
    const giveAndTakeId = { id: id };
    await API.graphql(graphqlOperation(deleteGiveAndTake, giveAndTakeId));
    await this.createMyOwnMessages(loggedUserName);
  }

  async handleFormSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    const currentUser = await Auth.currentAuthenticatedUser();
    const loggedUserName = currentUser.username;

    const thisMoment = new Date();

    let give = this.state.give;
    let take = this.state.take;

    if (give === "") {
      give = " ";
    }

    if (take === "") {
      take = " ";
    }
    if (give !== " " || take !== " ") {
      const obj = {
        give: give,
        take: take,
        DT_created: thisMoment.toISOString(),
        isValid: true,
        owner: loggedUserName,
      };
      console.log("obj is: ", obj);
      await API.graphql(graphqlOperation(createGiveAndTake, obj));

      this.setState({ hasError: false });
      this.setState({ displayFlashMsg: true });
      setTimeout(() => {
        this.setState({ displayFlashMsg: false });
      }, 1000);

      setTimeout(() => {
        this.setState({ give: "" });
      }, 1000);

      setTimeout(() => {
        this.setState({ take: "" });
      }, 1000);

      document.getElementById("marketInputSubmitID").blur();
    } else {
      this.setState({ hasError: true });
      this.setState({
        errorMessage: "At least one field must have value",
      });
      document.getElementById("marketInputSubmitID").blur();
    }

    await this.createMyOwnMessages(loggedUserName);
    this.setState({ hasError: false });
  }

  render() {
    return (
      <div className="container-fluid my-4">
        <h2>Market</h2>
        <h5 className="my-4">
          <div>Sharing is Caring</div>
          <div>Your right to share/get anything</div>
          <div>Our duty to take down inappropriate messages</div>
        </h5>
        {this.state.hasError ? (
          <p className="color_red">
            Error Found: &nbsp;
            {this.state.errorMessage}
          </p>
        ) : null}
        <form id="form12" onSubmit={this.handleFormSubmit}>
          <div className="form-group">
            <div>
              <label htmlFor="give">I want to share something:</label>
              <textarea
                className="form-control"
                id="give"
                placeholder={this.state.placeHolder}
                rows="6"
                value={this.state.give}
                onChange={this.handleGiveChange}
              />
            </div>
            <div className="my-4">
              <label htmlFor="take">I want to get something:</label>
              <textarea
                className="form-control"
                id="take"
                placeholder={this.state.placeHolder}
                rows="6"
                value={this.state.take}
                onChange={this.handleTakeChange}
              />
            </div>
            <div className="my-4">
              <input
                id="marketInputSubmitID"
                className="btn  btn-primary w-100"
                type="submit"
                value="Submit"
              />
              {this.state.displayFlashMsg ? (
                <span className="color_red mx-1 my-1">Request Posted</span>
              ) : null}
            </div>
            <div>
              <p>Other people's messages (Read ONLY)</p>
              <table
                className="text-center table table-responsive-sm table-striped
        table-bordered table-hover table-condensed"
              >
                <thead>
                  <tr>
                    <th>Message owner (username)</th>
                    <th>Shared with community</th>
                    <th>Got from community</th>
                  </tr>
                </thead>
                <tbody>{this.state.communityMessages}</tbody>
              </table>

              <p>My Own Messages (Can Update or Delete)</p>
              <table
                className="text-center table table-responsive-sm table-striped
        table-bordered table-hover table-condensed"
              >
                <thead>
                  <tr>
                    <th>Actions</th>
                    <th>Shared with community</th>
                    <th>Got from community</th>
                  </tr>
                </thead>
                <tbody>{this.state.myOwnMessages}</tbody>
              </table>
            </div>
          </div>
        </form>
        <MaxLiProduction />
      </div>
    );
  }
}
export default Market;
