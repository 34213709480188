import React, { Component } from "react";
import { NavLink as Link } from "react-router-dom";
import { getOnlineTrace } from "./SomeConstants";
import { API, graphqlOperation } from "aws-amplify";

class ShowDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      singleRow: {},
    };

    this.getItAndSetState(props.match.params.id);
  }

  async getItAndSetState(passedID) {
    console.log("passedID is: ", passedID);
    console.log("getOnlineTrace is: ", getOnlineTrace);

    const result = await API.graphql(
      graphqlOperation(getOnlineTrace, { id: passedID })
    );
    console.log("result immediately after await is: ", result);

    const singleRow = result.data.getOnlineTrace;
    console.log("singleRow is: ", singleRow);
    this.setState({ singleRow: singleRow });
  }

  handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  render() {
    return (
      <div className="container-fluid my-4">
        <form onSubmit={this.handleSubmit}>
          <div className="form-group">
            <h4>Note: Information is read ONLY. Cannot be changed.</h4>
          </div>

          <div>
            <label htmlFor="id_name">
              Name (Web/mobile app name or computer name or organization name):
            </label>
            <input
              className="form-control"
              id="id_name"
              maxLength="254"
              name="name"
              type="text"
              size="30"
              value={this.state.singleRow.name}
            />
          </div>
          <div>
            <label htmlFor="id_username">Username:</label>
            <input
              className="form-control"
              id="id_username"
              maxLength="255"
              name="username"
              type="text"
              size="30"
              value={this.state.singleRow.userName}
            />
          </div>
          <div>
            <label htmlFor="id_hintForAccess">
              Hint For Access (Plain password here? Acceptable):
            </label>
            <input
              className="form-control"
              id="id_hintForAccess"
              maxLength="255"
              name="hintForAccess"
              type="text"
              size="30"
              value={this.state.singleRow.hintForAccess}
            />
          </div>
          <div>
            <label htmlFor="id_monthly_charge">
              Monthly Charge (Format: $$$.$$):
            </label>
            <input
              className="form-control"
              id="id_monthly_charge"
              maxLength="255"
              name="monthly_charge"
              type="number"
              size="30"
              min="0.00"
              value={this.state.singleRow.monthlyCharge}
              step="0.01"
              required
            />
          </div>
          <div>
            <label htmlFor="id_creationDT">Created On:</label>
            <input
              className="form-control"
              id="id_creationDT"
              name="creationDT"
              type="text"
              size="30"
              value={this.state.singleRow.createdOn}
            />
          </div>
          <div>
            <label htmlFor="id_modificationDT">Modified On:</label>
            <input
              className="form-control"
              id="id_modificationDT"
              name="modificationDT"
              type="text"
              size="30"
              value={this.state.singleRow.modifiedOn}
            />
          </div>
          <div>
            <label htmlFor="id_security_questions">Security Questions:</label>
            <input
              className="form-control"
              id="id_security_questions"
              name="security_questions"
              type="text"
              size="30"
              value={this.state.singleRow.securityQuestions}
            />
          </div>
          <div>
            <label htmlFor="id_email">Email: </label>
            <input
              className="form-control"
              id="id_email"
              name="email"
              type="email"
              size="30"
              value={this.state.singleRow.email}
            />
          </div>
          <div>
            <label htmlFor="id_comment">Comment:</label>
            <input
              className="form-control"
              id="id_comment"
              name="comment"
              type="text"
              size="30"
              value={this.state.singleRow.comment}
            />
          </div>
        </form>

        <Link className="btn  btn-primary w-100 my-4" to="/accountLogin">
          Go Back
        </Link>
      </div>
    );
  }
}

export default ShowDetails;
