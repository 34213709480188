import React, { Component } from "react";

import { Auth, API, graphqlOperation } from "aws-amplify";

import { Doughnut, HorizontalBar, Line } from "react-chartjs-2";

import { listOT4Spending } from "./SomeConstants";

class Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      moreThan5Entries: false,
      data: {
        labels: ["Netflix", "medium.com", "Hulu", "ESPN.com", "WSJ.com"],
        datasets: [
          {
            label: "Spending",
            data: [16.99, 5.0, 11.99, 4.99, 15.6],
            backgroundColor: [
              "#003d73",
              "#0878a4",
              "#1ecfd6",
              "#edd170",
              "#c05640",
            ],
            hoverBackgroundColor: [
              "#003d73",
              "#0878a4",
              "#1ecfd6",
              "#edd170",
              "#c05640",
            ],
          },
        ],
      },
    };
  }

  async componentDidMount() {
    const currentUser = await Auth.currentAuthenticatedUser();
    const loggedUserName = currentUser.username;

    const requestFilter = {
      filter: {
        userNameOfTheOwner: {
          eq: loggedUserName,
        },
        monthlyCharge: {
          gt: 0.0,
        },
      },
      limit: 99999,
    };

    const {
      data: {
        listOnlineTraces: { items: aList },
      },
    } = await API.graphql(graphqlOperation(listOT4Spending, requestFilter));
    console.log("alist is: ", aList);

    const len = aList.length;
    console.log("len is: ", len);

    if (len >= 6) {
      let info = {
        labels: [],
        datasets: [
          {
            label: "Spending",
            data: [],
            backgroundColor: [],
            hoverBackgroundColor: [],
          },
        ],
      };

      const recycledColors = [
        "#003d73",
        "#0878a4",
        "#1ecfd6",
        "#edd170",
        "#c05640",
      ];

      for (let i = 0; i < aList.length; i++) {
        info.labels.push(aList[i].name);
        info.datasets[0].data.push(aList[i].monthlyCharge);
        info.datasets[0].backgroundColor.push(recycledColors[i % 5]);
        info.datasets[0].hoverBackgroundColor.push(recycledColors[i % 5]);
      }
      this.setState({ data: info });
      this.setState({ moreThan5Entries: true });
    } else {
      this.setState({ moreThan5Entries: false });
    }
  }

  render() {
    return (
      <div className="container-fluid my-4">
        <h4 className="text-center big150p my-4">
          {this.state.moreThan5Entries
            ? null
            : `Warning: Less than 6 entires
             that have non-zero values for monthly charge. 
             Charts are for illustration purposes ONLY. 
             Please enter more entries.`}
        </h4>
        <div className="text-center">
          <h5>Spending Manager&nbsp;(Doughnut)</h5>
          <Doughnut data={this.state.data} />
        </div>
        <div className="text-center mt-3">
          <h5>Spending Manager&nbsp;(Horizontal Bar)</h5>
          <HorizontalBar data={this.state.data} />
        </div>

        <div className="text-center mt-3">
          <h5>Spending Manager&nbsp;(Line)</h5>
          <Line data={this.state.data} />
        </div>
      </div>
    );
  }
}

export default Chart;
