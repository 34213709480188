import React, { Component } from "react";
import { NavLink as Link } from "react-router-dom";
import {
  getOnlineTrace,
  updateOnlineTrace,
  listTTPNamesOnlyWithAFilter,
  listDuplicateUserInfos,
  listTTPBasedOnTraceID,
  updateTraceTrustedPartner,
} from "./SomeConstants";
import { API, graphqlOperation, Auth } from "aws-amplify";

import "../index.css";

class UpdateDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: "",
      name: " ",
      monthlyCharge: 0.0,
      userName: " ",
      hintForAccess: " ",
      isPrivate: true,
      email: " ",
      createdOn: " ",
      modifiedOn: " ",
      securityQuestions: " ",
      comment: " ",
      userNameOfTheOwner: " ",

      oldName: " ",
      oldUserName: " ",
      oldHintForAccess: " ",

      hasError: false,
      errorMessage: " ",
    };

    this.getItAndSetState(props.match.params.id);

    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleMonthlyChargeChange = this.handleMonthlyChargeChange.bind(this);
    this.handleUserNameChange = this.handleUserNameChange.bind(this);
    this.handleHintForAccessChange = this.handleHintForAccessChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleCreatedOnChange = this.handleCreatedOnChange.bind(this);
    this.handleModifiedOnChange = this.handleModifiedOnChange.bind(this);
    this.handleCommentChange = this.handleCommentChange.bind(this);
    this.handleSecurityQuestionsChange = this.handleSecurityQuestionsChange.bind(
      this
    );

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async getItAndSetState(passedID) {
    console.log("passedID is: ", passedID);
    console.log("getOnlineTrace is: ", getOnlineTrace);

    const result = await API.graphql(
      graphqlOperation(getOnlineTrace, { id: passedID })
    );
    console.log("result immediately after await is: ", result);

    const singleRow = result.data.getOnlineTrace;
    console.log("singleRow is: ", singleRow);

    this.setState({ id: singleRow.id });
    this.setState({ name: singleRow.name.trim() });
    this.setState({ monthlyCharge: singleRow.monthlyCharge });
    this.setState({ userName: singleRow.userName.trim() });
    this.setState({ hintForAccess: singleRow.hintForAccess.trim() });
    this.setState({ email: singleRow.email.trim() });
    this.setState({ createdOn: singleRow.createdOn });
    this.setState({ modifiedOn: new Date().toISOString() });
    this.setState({ securityQuestions: singleRow.securityQuestions.trim() });
    this.setState({ comment: singleRow.comment.trim() });
    this.setState({ userNameOfTheOwner: singleRow.userNameOfTheOwner.trim() });

    // set the three OLD entries also. not forget.
    this.setState({ oldName: this.state.name });
    this.setState({ oldUserName: this.state.userName });
    this.setState({ oldHintForAccess: this.state.hintForAccess });
  }

  handleNameChange(event) {
    this.setState({ name: event.target.value });
  }

  handleMonthlyChargeChange(event) {
    this.setState({ monthlyCharge: event.target.value });
  }

  handleUserNameChange(event) {
    this.setState({ userName: event.target.value });
  }

  handleHintForAccessChange(event) {
    this.setState({ hintForAccess: event.target.value });
  }

  handleCreatedOnChange(event) {
    this.setState({ createdOn: event.target.value });
  }

  handleModifiedOnChange(event) {
    this.setState({ modifiedOn: event.target.value });
  }
  handleEmailChange(event) {
    this.setState({ email: event.target.value });
  }
  handleCommentChange(event) {
    this.setState({ comment: event.target.value });
  }

  handleSecurityQuestionsChange(event) {
    this.setState({ securityQuestions: event.target.value });
  }
  async sendEmailNow(email) {
    // Max Li 2021-03-24. The ability of sending emails
    // should be within a Lambda function, calling from
    // the client side. What I was thinking? I need to do it much
    // earlier...

    // Load the AWS SDK for Node.js
    var AWS = require("aws-sdk");
    // Set the region
    const region = "us-east-1";
    AWS.config.update({ region: region });

    // check out: https://github.com/aws-amplify/amplify-js/issues/1241
    const credentials = await Auth.currentCredentials();
    AWS.config.update({ credentials: credentials });
    // AWS.config.update({policy})
    // Create sendEmail params
    var params = {
      Destination: {
        /* required */
        CcAddresses: ["max.li@live.com", "ithreeman@outlook.com"],
        ToAddresses: [],
      },
      Message: {
        /* required */
        Body: {
          /* required */

          Text: {
            Charset: "UTF-8",
            Data: email,
          },
        },
        Subject: {
          Charset: "UTF-8",
          Data: "An important email message from ShareWisely.us",
        },
      },
      // Source: "SENDER_EMAIL_ADDRESS" /* required */,

      Source: "max.li@live.com" /* required */,
      ReplyToAddresses: [
        "max.li@live.com",
        /* more items */
      ],
    };

    // Create the promise and SES service object
    var sendPromise = new AWS.SES({ apiVersion: "2010-12-01" })
      .sendEmail(params)
      .promise();

    // Handle promise's fulfilled/rejected states
    sendPromise
      .then(function (data) {
        console.log(data.MessageId);
      })
      .catch(function (err) {
        console.error(err, err.stack);
      });
  }
  async getTTPNamesOnlyByTraceID(id) {
    const result2 = await API.graphql(
      graphqlOperation(listTTPNamesOnlyWithAFilter, {
        trace_id: id,
      })
    );
    const allTTPItems = result2.data.listTraceTrustedPartners.items;

    let allTTPNames = [];

    for (let i = 0; i < allTTPItems.length; i++) {
      allTTPNames.push(allTTPItems[i].trusted_partner_username);
    }

    return allTTPNames;
  }

  async handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    if (
      this.state.name.trim() === "" &&
      this.state.userName.trim() === "" &&
      this.state.hintForAccess.trim() === ""
    ) {
      this.setState({
        hasError: true,
        errorMessage:
          "At least one of them must have value: Name/Username/Hint For Access.",
      });
    } else {
      /* AWS DynamoDB does NOT allow empty strings in database */
      /*  As of 2019-04-11. The situation may change int the future */
      /* as a result, we need to change it to a string with a single */
      /* blank space inside it */

      let nameNotEmpty = this.state.name;
      if (nameNotEmpty === "") {
        nameNotEmpty = " ";
      }

      let userNameNotEmpty = this.state.userName;
      if (userNameNotEmpty === "") {
        userNameNotEmpty = " ";
      }

      let hintForAccessNotEmpty = this.state.hintForAccess;
      if (hintForAccessNotEmpty === "") {
        hintForAccessNotEmpty = " ";
      }

      let emailNotEmpty = this.state.email;
      if (emailNotEmpty === "") {
        emailNotEmpty = " ";
      }

      let securityQuestionsNotEmpty = this.state.securityQuestions;
      if (securityQuestionsNotEmpty === "") {
        securityQuestionsNotEmpty = " ";
      }

      let commentNotEmpty = this.state.comment;
      if (commentNotEmpty === "") {
        commentNotEmpty = " ";
      }

      let userNameOfTheOwnerNotEmpty = this.state.userNameOfTheOwner;
      if (userNameOfTheOwnerNotEmpty === "") {
        userNameOfTheOwnerNotEmpty = " ";
      }

      const obj = {
        id: this.state.id,
        name: nameNotEmpty,
        monthlyCharge: this.state.monthlyCharge,
        userName: userNameNotEmpty,
        hintForAccess: hintForAccessNotEmpty,
        isPrivate: true,
        createdOn: this.state.createdOn,
        modifiedOn: this.state.modifiedOn,
        email: emailNotEmpty,
        comment: commentNotEmpty,
        securityQuestions: securityQuestionsNotEmpty,
        userNameOfTheOwner: userNameOfTheOwnerNotEmpty,
      };
      console.log("obj is: ", obj);
      await API.graphql(graphqlOperation(updateOnlineTrace, obj));
      console.log("done with modifying Online Traces.");

      // steps for updating TraceTrustedPartner table also.
      if (
        this.state.oldName !== this.state.name ||
        this.state.oldUserName !== this.state.userName ||
        this.state.oldHintForAccess !== this.state.hintForAccess
      ) {
        // retrieve entries based on this.state.id
        // from TraceTrustedPartner table

        const ret = await API.graphql(
          graphqlOperation(listTTPBasedOnTraceID, { trace_id: this.state.id })
        );
        console.log("ret is: ", ret);

        const items = ret.data.listTraceTrustedPartners.items;
        console.log("items is: ", items);

        // get all TraceTrustedPartner based on trace_id
        // for each entry, we need to retrieve the old information first,
        // then we overwrite the old information with new ones by
        // using updateTraceTrustedPartner
        for (let i = 0; i < items.length; i++) {
          // console.log("i is: ", i);
          const id = items[i].id;
          const trace_id = items[i].trace_id;

          // replace three important attributes
          // very important. Get from the state
          // not the information retrieved from
          // the database
          const name = this.state.name;
          const userName = this.state.userName;
          const hintForAccess = this.state.hintForAccess;

          const sender_username = items[i].sender_username;
          const trusted_partner_username = items[i].trusted_partner_username;
          const DT_established = items[i].DT_established;

          const newObj = {
            id: id,
            trace_id: trace_id,
            name: name,
            userName: userName,
            hintForAccess: hintForAccess,
            sender_username: sender_username,
            trusted_partner_username: trusted_partner_username,
            DT_established: DT_established,
          };
          console.log("newObj is: ", newObj);
          await API.graphql(
            graphqlOperation(updateTraceTrustedPartner, newObj)
          );
        }
      }

      // find all users that this entry has been shared with.
      const usernames = await this.getTTPNamesOnlyByTraceID(this.state.id);
      // get the emails of those user names
      console.log("names are: ", usernames);
      for (let i = 0; i < usernames.length; i++) {
        const username = usernames[i];
        const duplicateUserInfoFilter = {
          filter: {
            username: {
              eq: username,
            },
          },
          limit: 99999,
        };
        const {
          data: {
            listDuplicateUserInfos: { items: aList },
          },
        } = await API.graphql(
          graphqlOperation(listDuplicateUserInfos, duplicateUserInfoFilter)
        );
        const email = aList[0].email;
        console.log("// : Send emails to a particular user's email address");
        console.log("email is: ", email);
        // : Send emails to a particular user's email address
        const emailContent =
          "You have received this email from ShareWisely.us " +
          " This email is to notify that one of the online credentials" +
          "has been changed. We are not going to email those credentials " +
          " to you directly. Instead, you are encouraged to log into " +
          " ShareWisely.us to know the latest updates. Since " +
          " The change would be the password itself, it is critial to " +
          "log into the system and found out immediately. Otherwise, " +
          "the online credentials you have known before may not work correctly";
        // await this.sendEmailNow(emailContent);
        console.log(emailContent);
      }

      this.props.history.push("/accountLogin");
    }
  }

  render() {
    return (
      <div className="container-fluid my-4">
        {this.state.hasError ? (
          <p className="color_red">
            Error Found:
            {this.state.errorMessage}
          </p>
        ) : null}

        <h4>Update Details</h4>

        <form onSubmit={this.handleSubmit}>
          <div className="form-group">
            <label htmlFor="id_name">
              Name (Web/mobile app name or computer/organization name):
            </label>
            <input
              className="form-control"
              id="id_name"
              maxLength="254"
              name="name"
              type="text"
              size="30"
              value={this.state.name}
              onChange={this.handleNameChange}
            />
          </div>
          <div>
            <label htmlFor="id_username">
              Username (Username or email or phone number):
            </label>
            <input
              className="form-control"
              id="id_username"
              maxLength="255"
              name="username"
              type="text"
              size="30"
              value={this.state.userName}
              onChange={this.handleUserNameChange}
            />
          </div>
          <div>
            <label htmlFor="id_hintForAccess">
              Hint (Not important? enter true password):
            </label>
            <input
              className="form-control"
              id="id_hintForAccess"
              maxLength="255"
              name="hintForAccess"
              type="text"
              size="30"
              value={this.state.hintForAccess}
              onChange={this.handleHintForAccessChange}
            />
          </div>
          <div>
            <label htmlFor="id_monthly_charge">
              Monthly Charge (format: $$$.$$)
            </label>
            <input
              className="form-control"
              id="id_monthly_charge"
              maxLength="255"
              name="monthly_charge"
              type="number"
              size="30"
              min="0.00"
              value={this.state.monthlyCharge}
              onChange={this.handleMonthlyChargeChange}
              step="0.01"
              required
            />
          </div>
          <div>
            <label htmlFor="id_creationDT">Created On:</label>
            <input
              className="form-control"
              id="id_creationDT"
              name="creationDT"
              type="text"
              size="30"
              value={this.state.createdOn}
              onChange={this.handleCreatedOnChange}
            />
          </div>
          <div>
            <label htmlFor="id_modificationDT">Modified On:</label>
            <input
              className="form-control"
              id="id_modificationDT"
              name="modificationDT"
              type="text"
              size="30"
              value={this.state.modifiedOn}
              onChange={this.handleModifiedOnChange}
            />
          </div>
          <div>
            <label htmlFor="id_security_questions">Security Questions:</label>
            <input
              className="form-control"
              id="id_security_questions"
              name="security_questions"
              type="text"
              size="30"
              value={this.state.securityQuestions}
              onChange={this.handleSecurityQuestionsChange}
            />
          </div>
          <div>
            <label htmlFor="id_email">Email:</label>
            <input
              className="form-control"
              id="id_email"
              name="email"
              type="email"
              size="30"
              value={this.state.email}
              onChange={this.handleEmailChange}
            />
          </div>
          <div>
            <label htmlFor="id_comment">Comment: &nbsp;</label>
            <input
              className="form-control"
              id="id_comment"
              name="comment"
              type="text"
              size="30"
              value={this.state.comment}
              onChange={this.handleCommentChange}
            />
          </div>
          <button
            className="btn  btn-primary w-100 my-4"
            type="submit"
            value="Submit"
          >
            Submit
          </button>
        </form>

        <Link className="btn  btn-info w-100" to="/accountLogin">
          Go Back (without modifying anything)
        </Link>
      </div>
    );
  }
}

export default UpdateDetails;
