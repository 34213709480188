import React, { Component } from "react";
import { FormGroup, FormControl } from "react-bootstrap";
import { API, graphqlOperation, Auth } from "aws-amplify";
import LoaderButton from "./LoaderButton";
import {
  createDuplicateUserInfo,
  createAboutMe,
} from "../components/SomeConstants";

export default class Signup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      username: "",
      password: "",
      email: "",
      confirmPassword: "",
      confirmationCode: "",
      newUser: null,
      hasError: false,
      errorMessage: "",
    };
  }

  validateForm() {
    return (
      this.state.username.length > 0 &&
      this.state.password.length > 0 &&
      this.state.email.length > 0 &&
      this.state.password === this.state.confirmPassword
    );
  }

  validateConfirmationForm() {
    return this.state.confirmationCode.length > 0;
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  userHasAuthenticated(authenticated) {
    this.setState({ isAuthenticated: authenticated });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({ isLoading: true });

    try {
      console.log("point11");

      const newUser = await Auth.signUp({
        username: this.state.username,
        password: this.state.password,
        attributes: {
          email: this.state.email,
        },
        validationData: [],
      });
      console.log("point22");

      this.setState({
        newUser,
      });
      this.setState({ hasError: false });
      this.setState({ errorMessage: "" });
      console.log("point33");
    } catch (e) {
      console.log("point44");

      if (e === "No current user") {
      } else {
        alert(e.message);
        this.setState({ hasError: true });
        this.setState({ errorMessage: e.message });
      }
    }
    console.log("point77");

    this.setState({ isLoading: false });

    console.log("point88");
  };

  handleConfirmationSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({ isLoading: true });

    try {
      await Auth.confirmSignUp(
        this.state.username,
        this.state.confirmationCode
      );
      await Auth.signIn(this.state.username, this.state.password);

      this.props.userHasAuthenticated(true);

      this.props.history.push("/accountLogin");
    } catch (e) {
      // alert("alert 141");
      // alert(e);
      // alert(e.message);
      this.setState({ hasError: true });
      this.setState({ errorMessage: e.message });

      this.setState({ isLoading: false });
    }

    // this is the place to insert User information
    // into our own duplicate DynamoDB table. It is a workaround solution
    // to get users information. Check it out
    // https://github.com/aws-amplify/amplify-js/issues/1324

    // Max Li 2019-07-18

    let userNameNotEmpty = this.state.username;
    if (userNameNotEmpty === "") {
      userNameNotEmpty = " ";
    }

    let passwordNotEmpty = this.state.password;
    if (passwordNotEmpty === "") {
      passwordNotEmpty = " ";
    }

    let emailNotEmpty = this.state.email;
    if (emailNotEmpty === "") {
      emailNotEmpty = " ";
    }

    let cellPhoneNotEmpty = "615-208-3675";
    if (cellPhoneNotEmpty === "") {
      cellPhoneNotEmpty = " ";
    }

    const thisMoment = new Date();
    const creationDT = thisMoment.toISOString();

    console.log("point55");

    const obj = {
      username: userNameNotEmpty,
      password: passwordNotEmpty,
      email: emailNotEmpty,
      cellPhone: cellPhoneNotEmpty,
      creationDT: creationDT,
    };

    console.log("point66b");

    console.log("The obj inside Signup.js is: ", obj);
    await API.graphql(graphqlOperation(createDuplicateUserInfo, obj));
    console.log(
      "Done with calling createDuplicateUserInfo. Saved into a database."
    );

    const aboutMeObj = {
      fullName: " ",
      preferredName: " ",
      DOB: " ",
      places: " ",
      schools: " ",
      books: " ",
      movies: " ",
      music: " ",
      sports: " ",
      userName: userNameNotEmpty,
    };
    console.log("createAboutMe inside Signup.js");

    console.log("aboutMeObj is:", aboutMeObj);

    await API.graphql(graphqlOperation(createAboutMe, aboutMeObj));
  };

  renderConfirmationForm() {
    return (
      <React.Fragment>
        <form onSubmit={this.handleConfirmationSubmit}>
          <FormGroup controlId="confirmationCode">
            <span>Verification Code</span>
            <FormControl
              autoFocus
              type="tel"
              value={this.state.confirmationCode}
              onChange={this.handleChange}
            />
            <span>Please check your email for the code.</span>
          </FormGroup>
          <LoaderButton
            block
            disabled={!this.validateConfirmationForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Verify"
            loadingText="Verifying…"
          />
        </form>
      </React.Fragment>
    );
  }

  renderForm() {
    return (
      <React.Fragment>
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="email">
            <span>Email: (Required to receive verification code)</span>
            <FormControl
              autoFocus
              type="email"
              value={this.state.email}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup controlId="username">
            <span>Username: (Required. Can be the same as Email)</span>
            <FormControl
              type="text"
              value={this.state.username}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup controlId="password">
            <span>Password: (Required)</span>
            <FormControl
              value={this.state.password}
              onChange={this.handleChange}
              type="password"
            />
          </FormGroup>
          <FormGroup controlId="confirmPassword">
            <span>Confirm Password: (Required)</span>
            <FormControl
              value={this.state.confirmPassword}
              onChange={this.handleChange}
              type="password"
            />
          </FormGroup>
          <ul>
            <li>Password rules:</li>
            <li>1. Length greater than or equal to 6.</li>
            <li>
              2. At least one character from the following groups: uppercase,
              lowercase, numeric, and symbol.
            </li>
          </ul>

          <LoaderButton
            block
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Create an Account"
            loadingText="Creating an Account…"
          />
        </form>
      </React.Fragment>
    );
  }

  render() {
    return (
      <div className="container-fluid my-4">
        <div className="Signup">
          {this.state.hasError ? (
            <p className="color_red">
              Error Found: &nbsp;
              {this.state.errorMessage}
            </p>
          ) : null}
          {this.state.newUser === null
            ? this.renderForm()
            : this.renderConfirmationForm()}
        </div>
      </div>
    );
  }
}
