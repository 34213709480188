import React, { Component } from "react";

class THead extends Component {
  render() {
    return (
      <thead>
        <tr>
          <th className="text-center">
            <span title="WebApp, MobileApp, computer or organization name">
              App Name
            </span>
          </th>

          <th className="text-center">
            <span title="User Name">User Name</span>
          </th>
          <th className="text-center">
            <span title="Hint for access">Hint</span>
          </th>
          <th className="text-center">
            <span title="Monthly Charge">$$$.$$</span>
          </th>

          <th className="text-center">
            <span title="More Details, Update, Delete...">Actions</span>
          </th>
        </tr>
      </thead>
    );
  }
}

export default THead;
