import React, { Component } from "react";
import MaxLiProduction from "./MaxLiProduction";

class Security extends Component {
  render() {
    return (
      <div className="container-fluid my-4">
        <div className="row col">
          <div className="card my-2">
            <div className="card-title display-4 text-center">
              Things that we do <strong>NOT</strong> reveal
            </div>
            <div className="card-body text-center">
              <p>
                By the very definition of secrecy, it is to the best interest of
                users that little information be released as regard to the
                security practices. As a result, we are NOT going to tell you
                the following: programming languages (client and server side),
                operating system, database, name and location of the hosting
                company.
              </p>
            </div>
          </div>

          <div className="card my-2">
            <div className="card-title text-center display-4">
              Human encryption
            </div>
            <div className="card-body text-center">
              <p>
                Most applications use encryption technology to make sure that
                outsiders do not have access to confidential information. We
                take a different approach. We call regular encryption
                <strong>
                  <kbd>machine encryption</kbd>
                </strong>
                or <code>level 1 encryption</code>. What separates us from
                competitors is
                <strong>
                  <kbd>human encryption</kbd>
                </strong>
                , or <code>level 2 encryption</code>. Human brain is excellent
                at associations among meaningful things. If we can create a hint
                for password, username or website name, our brain can quickly
                retrieve the original information. For example, if a password is
                Ieac@a17i1986, almost no one would know that it is from the
                initials of the phrase "I entered a college at age 17 in 1986".
                The password hint could be "college 17 1986". Given enough
                computing power, an organization is able to crack level 1
                encryption. How can a computer decrypt the hint of "college 17
                1986" to Ieac@a17i1986? It is am impossible job because there is
                no fixed algorithm to transform a hint to a password.
                Intelligence and law enforcement agencies such as FBI, CIA or
                NSA cannot decrypt the message encrypted by level 2 encryption.
              </p>
              <p>
                How to use level 2 encryption? It is a straightforward process.
                You only need to enter "partial information" into the sensitive
                field such as password, username and website name. The "partial
                information" serves as hints so that a user can immediately
                recall the base information while outsiders have no clue about
                it.
              </p>
            </div>
          </div>
          <div className="card my-2">
            <div className="card-title text-center display-4">
              Most fields are optional
            </div>
            <div className="card-body text-center">
              <p>
                For website name, username, hint and monthly charge, you can
                safely leave some fields blank. Since all three pieces of
                information are required in order to access a website, I can
                safely tell you the password without revealing much secret. For
                example,
                <code>Save?M0ney</code> is a real password used by us. It is
                useless because we did not reveal app name and username. It
                could be a password for chase.com, walmart.com or target.com or
                kroger.com. Just a guess.
              </p>
            </div>
          </div>
        </div>

        <MaxLiProduction />
      </div>
    );
  }
}

export default Security;
