import React, { Component } from "react";
import { NavLink as Link } from "react-router-dom";
import { Auth, API, graphqlOperation } from "aws-amplify";
import "../index.css";

import { createOnlineTrace } from "./SomeConstants";

import MaxLiProduction from "./MaxLiProduction";

class CreateOne extends Component {
  constructor(props) {
    super(props);
    const thisMoment = new Date();
    this.state = {
      name: "",
      monthlyCharge: 0.0,
      userName: "",
      hintForAccess: "",
      email: "",
      createdOn: thisMoment.toISOString(),
      modifiedOn: thisMoment.toISOString(),
      securityQuestions: "",
      comment: "",
      userNameOfTheOwner: "",
      hasError: false,

      errorMessage: "",
    };
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleMonthlyChargeChange = this.handleMonthlyChargeChange.bind(this);
    this.handleUserNameChange = this.handleUserNameChange.bind(this);
    this.handleHintForAccessChange = this.handleHintForAccessChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleCreatedOnChange = this.handleCreatedOnChange.bind(this);
    this.handleModifiedOnChange = this.handleModifiedOnChange.bind(this);
    this.handleSecurityQuestionsChange = this.handleSecurityQuestionsChange.bind(
      this
    );
    this.handleCommentChange = this.handleCommentChange.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleNameChange(event) {
    this.setState({ name: event.target.value });
  }

  handleMonthlyChargeChange(event) {
    this.setState({ monthlyCharge: event.target.value });
  }

  handleUserNameChange(event) {
    this.setState({ userName: event.target.value });
  }

  handleHintForAccessChange(event) {
    this.setState({ hintForAccess: event.target.value });
  }

  handleEmailChange(event) {
    this.setState({ email: event.target.value });
  }

  handleCreatedOnChange(event) {
    this.setState({ createdOn: event.target.value });
  }

  handleModifiedOnChange(event) {
    this.setState({ modifiedOn: event.target.value });
  }
  handleSecurityQuestionsChange(event) {
    this.setState({ securityQuestions: event.target.value });
  }

  handleCommentChange(event) {
    this.setState({ comment: event.target.value });
  }

  async componentDidMount() {
    const currentUser = await Auth.currentAuthenticatedUser();

    const userName = currentUser.username;
    console.log(
      "currentAuthenticatedUser.userame (inside CreateOne.js) is:",
      currentUser.username
    );
    this.setState({ userNameOfTheOwner: userName });
  }
  async handleSubmit(event) {
    event.preventDefault();

    if (
      this.state.name.trim() === "" &&
      this.state.userName.trim() === "" &&
      this.state.hintForAccess.trim() === ""
    ) {
      this.setState({
        hasError: true,
        errorMessage:
          "At least one of them must have value: Name/Username/Hint For Access.",
      });
    } else {
      /* AWS DynamoDB does NOT allow empty strings in database */
      /*  As of 2019-04-11. The situation may change int the future */
      /* as a result, we need to change it to a string with a single */
      /* blank space inside it */

      let nameNotEmpty = this.state.name;
      if (nameNotEmpty === "") {
        nameNotEmpty = " ";
      }

      let userNameNotEmpty = this.state.userName;
      if (userNameNotEmpty === "") {
        userNameNotEmpty = " ";
      }

      let hintForAccessNotEmpty = this.state.hintForAccess;
      if (hintForAccessNotEmpty === "") {
        hintForAccessNotEmpty = " ";
      }

      let emailNotEmpty = this.state.email;
      if (emailNotEmpty === "") {
        emailNotEmpty = " ";
      }

      let securityQuestionsNotEmpty = this.state.securityQuestions;
      if (securityQuestionsNotEmpty === "") {
        securityQuestionsNotEmpty = " ";
      }

      let commentNotEmpty = this.state.comment;
      if (commentNotEmpty === "") {
        commentNotEmpty = " ";
      }

      let userNameOfTheOwnerNotEmpty = this.state.userNameOfTheOwner;
      if (userNameOfTheOwnerNotEmpty === "") {
        userNameOfTheOwnerNotEmpty = " ";
      }

      const obj = {
        name: nameNotEmpty,
        monthlyCharge: this.state.monthlyCharge,
        userName: userNameNotEmpty,
        hintForAccess: hintForAccessNotEmpty,
        isPrivate: true,
        createdOn: this.state.createdOn,
        modifiedOn: this.state.modifiedOn,
        email: emailNotEmpty,
        comment: commentNotEmpty,
        securityQuestions: securityQuestionsNotEmpty,
        userNameOfTheOwner: userNameOfTheOwnerNotEmpty,
      };
      console.log("obj is: ", obj);
      await API.graphql(graphqlOperation(createOnlineTrace, obj));
      console.log("done with creating Online Traces. Into a database.");
      this.props.history.push("/accountLogin");
    }
  }

  render() {
    return (
      <div className="container-fluid my-4">
        {this.state.hasError ? (
          <p className="color_red">
            Error Found: &nbsp;
            {this.state.errorMessage}
          </p>
        ) : null}

        <form onSubmit={this.handleSubmit}>
          <div className="form-group">
            <label htmlFor="id_name">
              Name (Web/mobile app name or computer name or organization name):
              &nbsp;
            </label>
            <input
              className="form-control"
              id="id_name"
              maxLength="254"
              name="name"
              type="text"
              size="30"
              value={this.state.name}
              onChange={this.handleNameChange}
            />
          </div>

          <div>
            <label htmlFor="id_monthly_charge">
              Monthly Charge (Format: $$$.$$): &nbsp;
            </label>
            <input
              className="form-control"
              id="id_monthly_charge"
              maxLength="255"
              name="monthly_charge"
              type="number"
              size="30"
              min="0.00"
              value={this.state.monthlyCharge}
              onChange={this.handleMonthlyChargeChange}
              step="0.01"
            />
          </div>

          <div>
            <label htmlFor="id_username">Username: &nbsp;</label>
            <input
              className="form-control"
              id="id_username"
              maxLength="255"
              name="username"
              type="text"
              size="30"
              value={this.state.userName}
              onChange={this.handleUserNameChange}
            />
          </div>
          <div>
            <label htmlFor="hint">
              Hint For Access (Not important? Plain password here): &nbsp;
            </label>
            <input
              className="form-control"
              id="hint"
              maxLength="255"
              name="hintforaccess"
              type="text"
              size="30"
              value={this.state.hintForAccess}
              onChange={this.handleHintForAccessChange}
            />
          </div>
          <div>
            <label htmlFor="id_creationDT">
              Created On (Format: YYYY-MM-DD[THH:MM:SS.sssZ]):
            </label>
            <input
              className="form-control"
              id="id_creationDT"
              name="creationDT"
              type="text"
              size="30"
              value={this.state.createdOn}
              onChange={this.handleCreatedOnChange}
            />
          </div>
          <div>
            <label htmlFor="id_modificationDT">
              Modified On (Format: YYYY-MM-DD[THH:MM:SS.sssZ]):
            </label>
            <input
              className="form-control"
              id="id_modificationDT"
              name="modificationDT"
              type="text"
              size="30"
              value={this.state.modifiedOn}
              onChange={this.handleModifiedOnChange}
            />
          </div>
          <div>
            <label htmlFor="id_security_questions">
              Security Questions: &nbsp;
            </label>
            <input
              className="form-control"
              id="id_security_questions"
              name="security_questions"
              type="text"
              size="30"
              value={this.state.securityQuestions}
              onChange={this.handleSecurityQuestionsChange}
            />
          </div>
          <div>
            <label htmlFor="id_email">Email: &nbsp;</label>
            <input
              className="form-control"
              id="id_email"
              name="email"
              type="email"
              size="30"
              value={this.state.email}
              onChange={this.handleEmailChange}
            />
          </div>
          <div>
            <label htmlFor="id_comment">Comment: &nbsp;</label>
            <input
              className="form-control"
              id="id_comment"
              name="comment"
              type="text"
              size="30"
              value={this.state.comment}
              onChange={this.handleCommentChange}
            />
          </div>

          <div className="my-4">
            <input
              className="btn  btn-primary w-100"
              type="submit"
              value="Save"
            />
          </div>
        </form>

        <Link className="btn  btn-info w-100" to="/accountLogin">
          Go back and Show List
        </Link>
        <MaxLiProduction />
      </div>
    );
  }
}

export default CreateOne;
