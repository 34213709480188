import React, { Component } from "react";
import { NavLink as Link } from "react-router-dom";
import {
  getAccessPoint,
  updateAccessPoint,
  listAccessPointsWithAName
} from "./SomeConstants";
import { API, graphqlOperation, Auth } from "aws-amplify";

class UpdateAPDetails extends Component {
  async getItAndSetState(passedID) {
    const result = await API.graphql(
      graphqlOperation(getAccessPoint, { id: passedID })
    );
    console.log("result immediately after await is: ", result);

    const singleRow = result.data.getAccessPoint;
    console.log("singleRow is: ", singleRow);

    this.setState({ id: singleRow.id });
    this.setState({ name: singleRow.name.trim() });
    this.setState({ originalName: singleRow.name.trim() });

    this.setState({ purpose: singleRow.purpose.trim() });
    this.setState({ creationDT: singleRow.creationDT });
    this.setState({ modificationDT: new Date().toISOString() });
  }

  constructor(props) {
    super(props);

    this.state = {
      originalName: " ",
      name: " ",
      purpose: " ",
      creationDT: " ",
      modificationDT: " ",
      hasError: false,
      errorMessage: " "
    };

    this.getItAndSetState(props.match.params.id);

    this.handleNameChange = this.handleNameChange.bind(this);
    this.handlePurposeChange = this.handlePurposeChange.bind(this);
    this.handleCreationDTChange = this.handleCreationDTChange.bind(this);
    this.handleModificationDTChange = this.handleModificationDTChange.bind(
      this
    );

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleNameChange(event) {
    this.setState({ name: event.target.value });
  }

  handlePurposeChange(event) {
    this.setState({ purpose: event.target.value });
  }

  handleCreationDTChange(event) {
    this.setState({ creationDT: event.target.value });
  }

  handleModificationDTChange(event) {
    this.setState({ modificationDT: event.target.value });
  }

  async handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.state.name.trim() === "") {
      this.setState({
        hasError: true,
        errorMessage: "The name must have visible characters."
      });
    } else {
      const user = await Auth.currentAuthenticatedUser();
      const userName = user.username;

      const result = await API.graphql(
        graphqlOperation(listAccessPointsWithAName, {
          name: this.state.name,
          userNameOfTheOwner: userName
        })
      );

      // if it is true, the name has been taken. Error message
      if (
        result.data.listAccessPoints.items[0] &&
        result.data.listAccessPoints.items[0].name !== this.state.originalName
      ) {
        this.setState({
          hasError: true,
          errorMessage: "The name has taken. Please type in a different name."
        });
      } else {
        /* AWS DynamoDB does NOT allow empty strings in database */
        /*  As of 2019-04-11. The situation may change int the future */
        /* as a result, we need to change it to a string with a single */
        /* blank space inside it */

        let nameNotEmpty = this.state.name;
        if (nameNotEmpty === "") {
          nameNotEmpty = " ";
        }

        let purposeNotEmpty = this.state.purpose;
        if (purposeNotEmpty === "") {
          purposeNotEmpty = " ";
        }

        const accessPoint = {
          id: this.state.id,
          name: nameNotEmpty,
          purpose: purposeNotEmpty,
          creationDT: this.state.creationDT,
          modificationDT: this.state.modificationDT
        };
        await API.graphql(graphqlOperation(updateAccessPoint, accessPoint));
        this.props.history.push("/accesspoint");
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.state.hasError ? (
          <p className="color_red">
            Error Found:
            {this.state.errorMessage}
          </p>
        ) : null}

        <h4>Update Access Point Details</h4>

        <form onSubmit={this.handleSubmit}>
          <div className="form-group">
            <div>
              <label htmlFor="id_website">
                Access Point Name (Space not allowed):
              </label>
              <input
                className="form-control"
                id="id_website"
                maxLength="254"
                size="30"
                name="website"
                type="text"
                value={this.state.name}
                onChange={this.handleNameChange}
              />
            </div>
            <div>
              <label htmlFor="id_purpose">Purpose:</label>
              <input
                className="form-control"
                id="id_purpose"
                maxLength="255"
                size="30"
                name="purpose"
                type="text"
                value={this.state.purpose}
                onChange={this.handlePurposeChange}
              />
            </div>

            <div>
              <label htmlFor="id_creationDT">creationDT:</label>
              <input
                className="form-control"
                id="id_creationDT"
                maxLength="255"
                size="30"
                name="creationDT"
                type="text"
                value={this.state.creationDT}
                onChange={this.handleCreationDTChange}
              />
            </div>

            <div>
              <label htmlFor="id_modificationDT">modificationDT:</label>
              <input
                className="form-control"
                id="id_modificationDT"
                maxLength="255"
                size="30"
                name="modificationDT"
                type="text"
                value={this.state.modificationDT}
                onChange={this.handleModificationDTChange}
              />
            </div>

            <button
              className="btn btn-primary my-2"
              type="submit"
              value="Submit"
            >
              Submit
            </button>
          </div>
        </form>

        <Link className="btn btn-info" to="/accesspoint">
          Go Back (without modifying anything)
        </Link>
      </React.Fragment>
    );
  }
}

export default UpdateAPDetails;
