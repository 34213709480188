import React, { Component } from "react";
import netflix_ceo from "../images/netflix-ceo.png";
import HBO_ceo from "../images/HBO-ceo.png";
import netflix_is_cool from "../images/netflix-is-cool.png";
import MaxLiProduction from "./MaxLiProduction";

class SharingIsCaring extends Component {
  render() {
    return (
      <div className="container-fluid my-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div className="row mt-4 mb-4">
                <div className="col-6">
                  <img
                    width="400px"
                    className="img-fluid centered-img border-5px-solid-black"
                    src={netflix_ceo}
                    alt="netflix ceo says account sharing is ok"
                  />
                </div>

                <div className="col-6">
                  <p className="text-center padding-top-15p">
                    Sharing is OK, according to Netflix CEO. Check out:&nbsp;
                    <a href="https://techcrunch.com/2016/01/11/netflix-ceo-says-account-sharing-is-ok/">
                      Netflix CEO Says Account Sharing Is OK
                    </a>
                  </p>
                </div>
              </div>

              <div className="row mt-4 mb-4">
                <div className="col-6">
                  <p className="text-center padding-top-15p">
                    Netfix is cool with you sharing your account. Check
                    out:&nbsp;
                    <a href="https://www.cnet.com/news/netflix-is-cool-with-you-sharing-your-account/">
                      Netfix is cool with you sharing your account
                    </a>
                  </p>
                </div>

                <div className="col-6">
                  <img
                    width="400px"
                    className="img-fluid centered-img border-5px-solid-black"
                    src={netflix_is_cool}
                    alt="/netflix is cool with you sharing your account"
                  />
                </div>
              </div>

              <div className="row mt-4 mb-4">
                <div className="col-6">
                  <img
                    width="400px"
                    className="img-fluid centered-img border-5px-solid-black"
                    src={HBO_ceo}
                    alt="HBO CEO sharing usernames passwords"
                  />
                </div>

                <div className="col-6">
                  <p className="text-center padding-top-15p">
                    HBO CEO about sharing usernames and passwords. Check out:
                    &nbsp;
                    <a href="http://time.com/money/4043147/hbo-netflix-amazon-prime-share-account-password/">
                      the practice of sharing usernames and passwords serves as
                      a "terrific marketing vehicle for the next generation of
                      viewers," he said.
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <MaxLiProduction />
      </div>
    );
  }
}
export default SharingIsCaring;
