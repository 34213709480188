import React, { Component } from "react";
import { withAuthenticator } from "aws-amplify-react";
import { NavLink as Link } from "react-router-dom";
import { API, Auth, graphqlOperation } from "aws-amplify";

import {
  listAccessPointsWithAFilter,
  deleteAccessPoint,
} from "./SomeConstants";

import MaxLiProduction from "./MaxLiProduction";

/* import { makeComparator } from "../utils/funcs"; */
function makeComparator(key, order = "asc") {
  return (a, b) => {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) return 0;

    const aVal = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
    const bVal = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (aVal > bVal) comparison = 1;
    if (aVal < bVal) comparison = -1;

    return order === "desc" ? comparison * -1 : comparison;
  };
}

class AccessPoint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allValues: [],
      userName: "",
    };
    this.listAccessPoints();
  }

  async componentDidMount() {
    const user = await Auth.currentAuthenticatedUser();

    const userName = user.username;
    this.setState({ userName: userName });

    const results = await API.graphql(
      graphqlOperation(listAccessPointsWithAFilter, {
        userNameOfTheOwner: userName,
      })
    );

    console.log("Inside componentDidMount. result is: ", results);

    const sortedResult = results.data.listAccessPoints.items.sort(
      makeComparator("modificationDT", "desc")
    );

    this.setState({ allValues: sortedResult });
  }

  async handleDelete(id) {
    const AccessPointId = { id: id };
    await API.graphql(graphqlOperation(deleteAccessPoint, AccessPointId));
    this.listAccessPoints();
  }
  async listAccessPoints() {
    const user = await Auth.currentAuthenticatedUser();

    const userName = user.username;

    const objs = await API.graphql(
      graphqlOperation(listAccessPointsWithAFilter, {
        userNameOfTheOwner: userName,
      })
    );

    const sortedResult = objs.data.listAccessPoints.items.sort(
      makeComparator("modificationDT", "desc")
    );
    this.setState({ allValues: sortedResult });
  }

  render() {
    const data = this.getTableData();

    return (
      <div className="container-fluid">
        <h2 className="text-center">
          Access points that I can reveal credentials to
        </h2>

        <div className="container-fluid text-center mb-1 mt-l">
          <Link className="btn btn-primary mt-4 mb-4 mr-1" to="/createAP">
            Create a New AP
          </Link>
        </div>
        <table
          className="table table-responsive-sm table-striped
        table-bordered table-hover table-condensed"
        >
          <thead>
            <tr className="text-center">
              <th>Name</th>
              <th>Purpose</th>
              <th>Actions</th>
            </tr>
          </thead>

          <tbody>{data}</tbody>
        </table>
        <MaxLiProduction />
      </div>
    );
  }

  getTableData() {
    const data = this.state.allValues.map((item, i) => (
      <tr key={i} className="">
        <td className="text-center" key={i + 1}>
          {item.name}
        </td>
        <td className="text-center" key={i + 2}>
          {item.purpose}
        </td>
        <td className="text-center">
          <Link
            className="btn btn-primary mr-1"
            key={i + 3}
            to={`/showAPDetails/${item.id}`}
          >
            Details
          </Link>

          <Link
            className="btn btn-info mr-1"
            key={i + 4}
            to={`/updateAPDetails/${item.id}`}
          >
            Update
          </Link>

          <button
            key={i + 5}
            type="button"
            className="btn btn-danger"
            onClick={() => {
              if (window.confirm("Do you REALLY want to delete the record?")) {
                /* very important. Must create a const, then call it */
                /* Otherwise, it will not work */
                const removeToCollection = this.handleDelete.bind(
                  this,
                  item.id
                );
                removeToCollection();
              }
            }}
          >
            Delete
          </button>

          <Link
            className="btn btn-primary ml-1 mr-1"
            key={i + 6}
            to={`/${this.state.userName}/${item.name}`}
          >
            Show Credentials
          </Link>
        </td>
      </tr>
    ));
    return data;
  }
}

export default withAuthenticator(AccessPoint);
