import React, { Component } from "react";

import { Auth } from "aws-amplify";

// import { Link, withRouter } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import Routes from "./Routes";
import "./BelowIndexAboveApp.css";

class BelowIndexAboveApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      hasError: false,
      errorMessage: "",
    };
  }

  async componentDidMount() {
    console.log("componentDidMount Point1");
    try {
      console.log("componentDidMount Point2");

      if (await Auth.currentSession()) {
        this.userHasAuthenticated(true);
        console.log("componentDidMount Point3");
      }
    } catch (e) {
      console.log("componentDidMount Point4");
      console.log("e is:", e);

      if (e !== "No current user") {
        console.log("componentDidMount Point5");

        this.setState({ hasError: true });
        this.setState({
          errorMessage: "Internal error. Please try again later.",
        });
        console.log("componentDidMount Point6");
      }
    }
    console.log("componentDidMount Point7");
    // console.log("this is:", this);
    console.log("componentDidMount Point8");

    this.setState({ isAuthenticating: false });

    console.log("componentDidMount Point9");
  }

  userHasAuthenticated = (authenticated) => {
    this.setState({ isAuthenticated: authenticated });
  };

  handleLogout = async (event) => {
    await Auth.signOut();

    this.userHasAuthenticated(false);

    this.props.history.push("/login");
    // this.props.history.push("/login");
  };

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
    };

    return (
      !this.state.isAuthenticating && (
        <div className="OTNavBar justify-content-center">
          <Navbar
            sticky="top"
            bg="light"
            className=" justify-content-center"
            expand="lg"
          >
            <Navbar.Brand className="">
              <LinkContainer className="" to="/" exact>
                <Nav.Link to="/">Share Wisely</Nav.Link>
              </LinkContainer>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav justify variant="pills" className="mx-1">
                <LinkContainer className="" to="/security" exact>
                  <Nav.Link className="mx-1">
                    <span className=" ">Security</span>
                  </Nav.Link>
                </LinkContainer>

                <NavDropdown title="Info" id="basic-nav-dropdown1">
                  <NavDropdown.Divider />

                  <LinkContainer
                    id="SharingIsCaring"
                    to="/sharingiscaring"
                    exact
                  >
                    <Nav.Link className="mx-1 SharingIsCaringClass">
                      <span className="color_black">Sharing Is Caring</span>
                    </Nav.Link>
                  </LinkContainer>

                  <NavDropdown.Divider />

                  <LinkContainer className="" to="/contact" exact>
                    <Nav.Link className="mx-1 SharingIsCaringClass">
                      <span className="color_black">Contact</span>
                    </Nav.Link>
                  </LinkContainer>
                  <NavDropdown.Divider />
                </NavDropdown>

                {this.state.isAuthenticated ? (
                  <React.Fragment>
                    <NavDropdown title="Credentials" id="basic-nav-dropdown2">
                      <LinkContainer className="" to="/accountLogin" exact>
                        <Nav.Link className="mx-1 SharingIsCaringClass">
                          <span className="color_black">My List</span>
                        </Nav.Link>
                      </LinkContainer>

                      <NavDropdown.Divider />

                      <LinkContainer className="" to="/accesspoint" exact>
                        <Nav.Link className="mx-1 SharingIsCaringClass">
                          <span className="color_black">Access Points</span>
                        </Nav.Link>
                      </LinkContainer>

                      <NavDropdown.Divider />

                      <LinkContainer className="" to="/partialsharing" exact>
                        <Nav.Link className="mx-1 SharingIsCaringClass">
                          <span className="color_black">Partial Sharing</span>
                        </Nav.Link>
                      </LinkContainer>
                    </NavDropdown>

                    <LinkContainer className="" to="/settings" exact>
                      <Nav.Link className="mx-1">
                        <span className="">Settings</span>
                      </Nav.Link>
                    </LinkContainer>

                    <Nav.Link className="mx-1 " onClick={this.handleLogout}>
                      <span className="">Logout</span>
                    </Nav.Link>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <LinkContainer className="" to="/signup" exact>
                      <Nav.Link className="">
                        <span className=" display_inline_block width20remImportant">
                          Create an Account
                        </span>
                      </Nav.Link>
                    </LinkContainer>

                    <LinkContainer className="" to="/login" exact>
                      <Nav.Link className="mx-1">
                        <span className="">Login</span>
                      </Nav.Link>
                    </LinkContainer>
                  </React.Fragment>
                )}
              </Nav>
            </Navbar.Collapse>
          </Navbar>

          <div>
            <Routes childProps={childProps} />
          </div>

          {this.state.hasError ? (
            <p className="color_red">
              Error Found: &nbsp;
              {this.state.errorMessage}
            </p>
          ) : null}
        </div>
      )
    );
  }
}

export default withRouter(BelowIndexAboveApp);
// export default BelowIndexAboveApp;
