import React from "react";
import ReactDOM from "react-dom";
import "./index.css";

// Need to understand the difference
// between BrowserRouter and HashRouter
// import { BrowserRouter } from "react-router-dom";
import { HashRouter } from "react-router-dom";
// import { BrowserRouter } from "react-router-dom";

import BelowIndexAboveApp from "./BelowIndexAboveApp";
// import { serviceWorker } from "./serviceWorker";

// ReactDOM.render(<BelowIndexAboveApp />, document.getElementById("root"));
ReactDOM.render(
  <HashRouter>
    <BelowIndexAboveApp />
  </HashRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

// serviceWorker.unregister();
