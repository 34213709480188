import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import { FormGroup, FormControl } from "react-bootstrap";
import LoaderButton from "./LoaderButton";
// import "./Login.css";

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      username: "",
      password: "",
      isAuthenticated: false,
      isAuthenticating: true,
      hasError: false,
      errorMessage: ""
    };
  }

  userHasAuthenticated(authenticated) {
    this.setState({ isAuthenticated: authenticated });
  }

  validateForm() {
    return this.state.username.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      console.log("pointA");
      await Auth.signIn(this.state.username, this.state.password);
      this.props.userHasAuthenticated(true);
      console.log("pointB");

      console.log("pointC");

      this.props.history.push("/accountLogin");
    } catch (e) {
      // console.log("Should display User does not exist.");
      this.setState({ hasError: true });
      this.setState({ errorMessage: "User does not exist. Please try again." });
      // alert(e.message);
      this.setState({ isLoading: false });
    }
  };

  render() {
    return (
      <div className="container-fluid my-4">
        {this.state.hasError ? (
          <p className="color_red">
            Error Found: &nbsp;
            {this.state.errorMessage}
          </p>
        ) : null}

        <div className="Login">
          <form onSubmit={this.handleSubmit}>
            <FormGroup controlId="username">
              <span>Username:</span>
              <FormControl
                autoFocus
                type="username"
                value={this.state.username}
                onChange={this.handleChange}
              />
            </FormGroup>
            <FormGroup controlId="password">
              <span>Password:</span>
              <FormControl
                value={this.state.password}
                onChange={this.handleChange}
                type="password"
              />
            </FormGroup>
            <Link to="/login/reset">Forgot password?</Link>
            <LoaderButton
              className="btn "
              block
              disabled={!this.validateForm()}
              type="submit"
              isLoading={this.state.isLoading}
              text="Login"
              loadingText="Logging in…"
            />
          </form>
        </div>
      </div>
    );
  }
}
