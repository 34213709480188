export const createOnlineTrace = `mutation createOnlineTrace(
  $name: String!, 
  $monthlyCharge: Float, 
  $userName: String, 
  $hintForAccess: String,
  $isPrivate: Boolean,
  $createdOn: String,
  $modifiedOn: String,
  $email: String,
  $comment: String,
  $securityQuestions: String,
  $userNameOfTheOwner: String){
  createOnlineTrace(input:{
    name: $name
    monthlyCharge: $monthlyCharge
    userName: $userName
    hintForAccess: $hintForAccess
    isPrivate: $isPrivate
    createdOn: $createdOn
    modifiedOn: $modifiedOn
    email: $email
    comment: $comment
    securityQuestions: $securityQuestions
    userNameOfTheOwner: $userNameOfTheOwner}){
    id
  }
}`;

export const listOTIDsWithAFilter = `query listOnlineTraces (
  $userNameOfTheOwner: String!){
    listOnlineTraces (
      filter: {
        userNameOfTheOwner: {
          eq: $userNameOfTheOwner
        }
    }, limit: 99999
    ){
      items{
        id
      }
    }
  }`;

export const readOTBasedOnID = `query readOTBasedOnID (
    $trace_id: ID!){
      listOnlineTraces (
        filter: {
          id: {
            eq: $trace_id
          }
      }, limit: 99999
      ){
        items{
          name
          userName
          hintForAccess
        }
      }
    }`;

export const readOnlineTracesWithAFilter = `query listOnlineTraces (
$userNameOfTheOwner: String!){
  listOnlineTraces (
    filter: {
      userNameOfTheOwner: {
        eq: $userNameOfTheOwner
      }
  }, limit: 99999
  ){
    items{
      id
      name
      monthlyCharge
      userName
      hintForAccess
      isPrivate
      createdOn
      modifiedOn
      email
      comment
      securityQuestions
    }
  }
}`;

export const getOnlineTrace = `query GetOnlineTrace($id: ID!) {
  getOnlineTrace(id: $id) {
    id
    name
    monthlyCharge
    userName
    hintForAccess
    isPrivate
    createdOn
    modifiedOn
    email
    comment
    securityQuestions
    userNameOfTheOwner
  }
}
`;

export const listOTPublicAttributes = `query listOTPublicAttributes (
  $trace_id: ID!,
  $userNameOfTheOwner: String!){
      listOnlineTraces(
       filter: { 
         and: [
           {id: {
             eq: $trace_id
           }},
           {userNameOfTheOwner: {
             eq: $userNameOfTheOwner
           }}
         ]
       }, 
       limit: 99999
     ){
       items{
         name
         userName
         hintForAccess
         comment
       }
     }
   }`;

export const updateOnlineTrace = `mutation updateOnlineTrace(
  $id: ID!,
  $name: String, 
  $monthlyCharge: Float,
  $userName: String,
  $hintForAccess: String,
  $isPrivate: Boolean,
  $createdOn: String,
  $modifiedOn: String,
  $email: String,
  $comment: String,
  $securityQuestions: String){
  updateOnlineTrace(input:{
    id: $id
    name: $name
    monthlyCharge: $monthlyCharge
    userName: $userName
    hintForAccess: $hintForAccess
    isPrivate: $isPrivate
    createdOn: $createdOn
    modifiedOn: $modifiedOn
    email: $email
    comment: $comment
    securityQuestions: $securityQuestions}){
    id
    name
    monthlyCharge
    userName
    hintForAccess
    email
    isPrivate
    createdOn
    modifiedOn
    email
    comment
    securityQuestions
    }
}`;

export const deleteOnlineTrace = `mutation deleteOnlineTrace($id: ID!){
  deleteOnlineTrace(input:{
    id: $id
  }){
    id
  }
}`;

export const createTraceAccessPoint = `mutation createTraceAccessPoint(
  $trace_id: String!, 
  $access_point_id: String!,
  $DT_established: String) {
  createTraceAccessPoint(input:{
    trace_id: $trace_id
    access_point_id: $access_point_id
    DT_established: $DT_established}){
    trace_id
    access_point_id
    DT_established
  }
}`;

export const deleteTraceAccessPoint = `mutation deleteTraceAccessPoint($id: ID!){
  deleteTraceAccessPoint(input:{
    id: $id
  }){
    id
  }
}`;

export const createAccessPoint = `mutation createAccessPoint(
  $name: String!, 
  $purpose: String,
  $creationDT: String,
  $modificationDT: String,
  $userNameOfTheOwner: String) {
  createAccessPoint(input:{
    name: $name
    purpose: $purpose
    creationDT: $creationDT
    modificationDT: $modificationDT
    userNameOfTheOwner: $userNameOfTheOwner}){
    id
    name
    purpose
    creationDT
    modificationDT
    userNameOfTheOwner
  }
}`;

export const getAccessPoint = `query GetAccessPoint($id: ID!) {
  getAccessPoint(id: $id) {
    id
    name
    purpose
    creationDT
    modificationDT
    userNameOfTheOwner
  }
}
`;

export const listAccessPointsWithAName = `query listAccessPointsWithAName (
  $name: String!, $userNameOfTheOwner: String!){
    listAccessPoints (
      filter: { 
        and: [
        {name: {
          eq: $name
        }},
        {userNameOfTheOwner: {
          eq: $userNameOfTheOwner
        }}
        ]
      }, limit: 99999
    ){
      items {
        id
        name
      }
    }
  }`;

export const deleteAccessPoint = `mutation deleteAccessPoint($id: ID!) {
  deleteAccessPoint(input: {
    id: $id
  }) {
    id
  }
}
`;

export const updateAccessPoint = `mutation updateAccessPoint(
  $id: ID!,$name: String, 
  $purpose: String,
  $creationDT: String,
  $modificationDT: String,
  $userNameOfTheOwner: String){
  updateAccessPoint(input:{
    id: $id
    name: $name
    purpose: $purpose,
    creationDT: $creationDT,
    modificationDT: $modificationDT,
    userNameOfTheOwner: $userNameOfTheOwner}){
    id
    name
    purpose
    creationDT
    modificationDT
    userNameOfTheOwner
    }
}`;

export const listAccessPointsWithAFilter = `query ListAccessPoints($userNameOfTheOwner: String!){
  listAccessPoints(filter: {
    userNameOfTheOwner: {
      eq: $userNameOfTheOwner
    }
  }, limit: 99999
  ) {
    items {
      id
      name
      purpose
      creationDT
      modificationDT
    }    
  }
}
`;

export const listAccessPoints = `query ListAccessPoints{
  listAccessPoints {
    items {
      id
      name
      purpose
      creationDT
      modificationDT
    }    
  }
}
`;

export const createTraceTrustedPartner = `mutation createTraceTrustedPartner(
  $trace_id: String!, 
  $name: String,
  $userName: String,
  $hintForAccess: String,
  $trusted_partner_username: String!,
  $sender_username: String!,
  $DT_established: String) {
  createTraceTrustedPartner(input:{
    trace_id: $trace_id
    name: $name,
    userName: $userName,
    hintForAccess: $hintForAccess,
    trusted_partner_username: $trusted_partner_username
    sender_username: $sender_username
    DT_established: $DT_established
  }){
    trace_id
    trusted_partner_username
    DT_established
  }
}`;

export const deleteTraceTrustedPartner = `mutation deleteTraceTrustedPartner($id: ID!) {
  deleteTraceTrustedPartner(input: {
    id: $id
  }) {
    id
  }
}
`;

export const listAPIDByAPName = `query listAPIDByAPName (
  $name: String!) {
    listAccessPoints (
      filter: {
        name: {
          eq: $name
        }
      }, limit: 99999
    ) {
      items {
        id
      }
    }
  }
`;

export const listTTPBasedOnTrustedPartnerName = `query listTTPBasedOnTrustedPartnerName (
  $trusted_partner_username: String!){
    listTraceTrustedPartners (
      filter: {
        trusted_partner_username: {
          eq: $trusted_partner_username
        }
    }, limit: 99999
    ){
      items{
        id
        trace_id
        name
        userName
        hintForAccess
        sender_username
        DT_established
      }
    }
  }`;

export const listTTPBasedOnTraceID = `query listTTPBasedOnTraceID (
    $trace_id: String!){
      listTraceTrustedPartners (
        filter: {
          trace_id: {
            eq: $trace_id
          }
      }, limit: 99999
      ){
        items{
          id
          trace_id
          name
          userName
          hintForAccess
          sender_username
          trusted_partner_username
          DT_established
        }
      }
    }`;

export const updateTraceTrustedPartner = `mutation updateTraceTrustedPartner(
      $id: ID!,
      $trace_id: String,
      $name: String,
      $userName: String,
      $hintForAccess: String,
      $sender_username: String,
      $trusted_partner_username: String,
      $DT_established: String){
      updateTraceTrustedPartner(input:{
        id: $id,
        trace_id: $trace_id,
        name: $name,
        userName: $userName,
        hintForAccess: $hintForAccess,
        sender_username: $sender_username,
        trusted_partner_username: $trusted_partner_username,
        DT_established: $DT_established
      }){
        id
        }
    }`;

export const listTTPBasedOnSenderUserName = `query listTTPBasedOnSenderUserName (
  $sender_username: String!){
    listTraceTrustedPartners (
      filter: {
        sender_username: {
          eq: $sender_username
        }
    }, limit: 99999
    ){
      items{
        id
        trace_id
        name
        userName
        hintForAccess
        trusted_partner_username
        DT_established
      }
    }
  }`;

export const listTraceTrustedPartnersWithAFilter = `query listTraceTrustedPartners (
  $trace_id: String!){
    listTraceTrustedPartners (
      filter: {
        trace_id: {
          eq: $trace_id
        }
    }, limit: 99999
    ){
      items{
        id
        trace_id
        trusted_partner_username
        DT_established
      }
    }
  }`;

export const listTraceAccessPointByAPID = `query listTraceAccessPointByAPID (
  $access_point_id: String!) {
    listTraceAccessPoints (
      filter: {
        access_point_id: {
          eq: $access_point_id
        }
      }, limit: 99999
    ) {
      items {
        trace_id
      }
    }
  }`;

export const listTTPNamesOnlyWithAFilter = `query listTraceTrustedPartners (
    $trace_id: String!){
      listTraceTrustedPartners (
        filter: {
          trace_id: {
            eq: $trace_id
          }
      }, limit: 99999
      ){
        items{
          trusted_partner_username
        }
      }
    }`;

export const listTraceIDsOnlyWithAFilter = `query listTraceTrustedPartners (
      $trusted_partner_username: String!){
        listTraceTrustedPartners (
          filter: {
            trusted_partner_username: {
              eq: $trusted_partner_username
            }
        }, limit: 99999
        ){
          items{
            trace_id
          }
        }
      }`;

export const listIDByTraceIDAccessPointID = `query listTraceAccessPoints(
  $trace_id: String!,
  $access_point_id: String!){
    listTraceAccessPoints
    (
      filter: { 
        and: [
        {trace_id: {
          eq: $trace_id
        }},
        {access_point_id: {
          eq: $access_point_id
        }}
        ]
      }, 
      limit: 99999
    ){
      items{
        id
      }
    }
  }`;

export const createGiveAndTake = `mutation createGiveAndTake(
  $give: String, 
  $take: String, 
  $owner: String, 
  $DT_created: String,
  $isValid: Boolean){
  createGiveAndTake(input:{
    give: $give
    take: $take
    owner: $owner
    DT_created: $DT_created
    isValid: $isValid}){
    id
  }
}`;

export const deleteGiveAndTake = `mutation deleteGiveAndTake($id: ID!){
  deleteGiveAndTake(input:{
    id: $id
  }){
    id
  }
}`;

export const listGiveAndTakes = `query ListGiveAndTakes(
  $filter: ModelGiveAndTakeFilterInput
  $limit: Int
  $nextToken: String){
    listGiveAndTakes(
      filter: $filter, 
      limit: $limit, 
      nextToken: $nextToken){
      items{
        id
        give
        take
        owner
        DT_created
        isValid
      }
    }
  }`;

export const createMessaging = `mutation createMessaging(
    $from: String, 
    $to: String, 
    $content: String, 
    $DT_created: String,
    $is_deleted: Boolean){
    createMessaging(input:{
      from: $from
      to: $to
      content: $content
      DT_created: $DT_created
      is_deleted: $is_deleted}){
      id
    }
  }`;

export const deleteMessaging = `mutation deleteMessaging($id: ID!){
    deleteMessaging(input:{
      id: $id
    }){
      id
    }
  }`;

export const listMessagings = `query ListMessagings(
    $filter: ModelMessagingFilterInput
    $limit: Int
    $nextToken: String){
      listMessagings(
        filter: $filter, 
        limit: $limit, 
        nextToken: $nextToken){
        items{
          id
          from
          to
          content
          DT_created
          is_deleted
        }
      }
    }`;

export const listOT4Spending = `query listOT4Spending(
  $filter: ModelOnlineTraceFilterInput
  $limit: Int
  $nextToken: String){
    listOnlineTraces(
      filter: $filter, 
      limit: $limit, 
      nextToken: $nextToken){
      items{
        id
        name
        monthlyCharge
      }
    }
  }`;

export const updateAboutMe = `mutation updateAboutMe(
    $id: ID!,
    $fullName: String, 
    $preferredName: String,
    $DOB: String,
    $places: String,
    $schools: String,
    $books: String,
    $movies: String,
    $music: String,
    $sports: String,
    $userName: String){
      updateAboutMe(input:{
      id: $id
      fullName: $fullName
      preferredName: $preferredName
      DOB: $DOB
      places: $places
      schools: $schools
      books: $books
      movies: $movies
      music: $music
      sports: $sports
      userName: $userName}){
      id
      }
  }`;
export const getAboutMe = `query getAboutMe {
    getAboutMe {
      id
      fullName 
      preferredName
      DOB
      places
      schools
      books
      movies
      music
      sports
      userName
    }
  }
  `;

export const createAboutMe = `mutation createAboutMe(
    $fullName: String, 
    $preferredName: String,
    $DOB: String,
    $places: String,
    $schools: String,
    $books: String,
    $movies: String,
    $music: String,
    $sports: String,
    $userName: String){
      createAboutMe(input:{
        fullName: $fullName
        preferredName: $preferredName
        DOB: $DOB
        places: $places
        schools: $schools
        books: $books
        movies: $movies
        music: $music
        sports: $sports
        userName: $userName}
      ){
      id
    }
  }`;
export const listAboutMes = `query listAboutMes(
  $filter: ModelAboutMeFilterInput
  $limit: Int
  $nextToken: String){
      listAboutMes (
        filter: $filter, 
        limit: $limit, 
        nextToken: $nextToken){
        items{
          id
          fullName
          preferredName
          DOB
          places
          schools
          books
          movies
          music
          sports
          userName
        }
      }
    }`;

export const createDuplicateUserInfo = `mutation createDuplicateUserInfo(
      $username: String!, 
      $password: String,
      $email: String,
      $cellPhone: String,
      $creationDT: String){
        createDuplicateUserInfo(input:{
          username: $username
          password: $password
          email: $email
          cellPhone: $cellPhone
          creationDT: $creationDT
        }
        ){
        id
      }
    }`;

export const getDuplicateUserInfo = `query GetDuplicateUserInfo($id: ID!) {
      getDuplicateUserInfo(id: $id) {
        id
        username
        password
        email
        cellPhone
        creationDT
      }
    }
    `;

export const listDuplicateUserInfos = `query listDuplicateUserInfos(
      $filter: ModelDuplicateUserInfoFilterInput
      $limit: Int
      $nextToken: String){
          listDuplicateUserInfos (
            filter: $filter, 
            limit: $limit, 
            nextToken: $nextToken){
            items{
              id
              username
              password
              email
              cellPhone
              creationDT
            }
          }
        }`;
