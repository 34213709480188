import React, { Component } from "react";
import { API, graphqlOperation, Auth } from "aws-amplify";
import {
  listTTPNamesOnlyWithAFilter,
  listTTPBasedOnSenderUserName,
  listTTPBasedOnTrustedPartnerName,
} from "./SomeConstants";
import MaxLiProduction from "./MaxLiProduction";

class PartialSharing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toOthers: [],
      fromOthers: [],
    };
    this.setToOthers();
    this.setFromOthers();
  }

  async setFromOthers() {
    console.log("inside setFromOthers()");
    const currentUser = await Auth.currentAuthenticatedUser();
    const userName = currentUser.username;

    // since it is for fromOthers. I, represented by userName,
    // is being trusted (trusted_partner_username) by senders
    // (get a list of sender_user_name)
    const objs = await API.graphql(
      graphqlOperation(listTTPBasedOnTrustedPartnerName, {
        trusted_partner_username: userName,
      })
    );
    console.log(
      "objs returned from listTTPBasedOnTrustedPartnerName is:",
      objs
    );
    const items = objs.data.listTraceTrustedPartners.items;
    console.log("items is: ", items);

    let allOnlineTraces = [];
    for (let i = 0; i < items.length; i++) {
      console.log("i is: ", i);

      const name = items[i].name;
      const userName = items[i].userName;
      const hintForAccess = items[i].hintForAccess;

      const SenderUserName = items[i].sender_username;
      allOnlineTraces.push({
        userNameOfTheOwner: SenderUserName,
        name: name,
        userName: userName,
        hintForAccess: hintForAccess,
      });
    }
    console.log("allOnlineTraces is:", allOnlineTraces);
    this.setState({ fromOthers: allOnlineTraces });
  }

  async setToOthers() {
    console.log("inside setToOthers()");
    const currentUser = await Auth.currentAuthenticatedUser();
    const userName = currentUser.username;

    const objs = await API.graphql(
      graphqlOperation(listTTPBasedOnSenderUserName, {
        sender_username: userName,
      })
    );
    // console.log("objs returned from listTTPBasedOnSenderUserName is:", objs);
    const items = objs.data.listTraceTrustedPartners.items;
    // console.log("items is: ", items);

    let allTrustedPartners = [];
    for (let i = 0; i < items.length; i++) {
      // console.log("i is: ", i);

      const name = items[i].name;
      const userName = items[i].userName;
      const hintForAccess = items[i].hintForAccess;
      const trustedPartnerName = items[i].trusted_partner_username;

      allTrustedPartners.push({
        name: name,
        userName: userName,
        hintForAccess: hintForAccess,
        trustedPartnerName: trustedPartnerName,
      });
    }
    // console.log("allTrustedPartners is:", allTrustedPartners);
    this.setState({ toOthers: allTrustedPartners });
  }

  async getTTPNamesOnlyByTraceID(id) {
    const result2 = await API.graphql(
      graphqlOperation(listTTPNamesOnlyWithAFilter, {
        trace_id: id,
      })
    );
    const allTTPItems = result2.data.listTraceTrustedPartners.items;
    let allTTPNames = [];
    for (let i = 0; i < allTTPItems.length; i++) {
      allTTPNames.push(allTTPItems[i].trusted_partner_username);
    }
    return allTTPNames;
  }
  getTraceTrustedPartners() {
    const resultData = this.state.toOthers.map((item, i) => (
      <tr key={i}>
        <td className="text-center" key={i + 1}>
          {item.name}
        </td>
        <td className="text-center" key={i + 2}>
          {item.userName}
        </td>
        <td className="text-center" key={i + 3}>
          {item.hintForAccess}
        </td>
        <td className="text-center" key={i + 4}>
          {item.trustedPartnerName}
        </td>
      </tr>
    ));

    return resultData;
  }
  getAllOnlineTraces() {
    const resultData = this.state.fromOthers.map((item, i) => (
      <tr key={i}>
        <td className="text-center" key={i + 1}>
          {item.userNameOfTheOwner}
        </td>
        <td className="text-center" key={i + 2}>
          {item.name}
        </td>
        <td className="text-center" key={i + 3}>
          {item.userName}
        </td>
        <td className="text-center" key={i + 4}>
          {item.hintForAccess}
        </td>
      </tr>
    ));

    return resultData;
  }

  render() {
    const dataTraceTrustedPartners = this.getTraceTrustedPartners();
    const dataAllOnlineTraces = this.getAllOnlineTraces();
    return (
      <div className="container-fluid my-4">
        <p className="text-center">
          Partial sharing of traces: to others AND from others
        </p>
        <div className="panel panel-primary">
          <div className="panel-heading text-center mb-4 mt-4">
            <strong>TO others:&nbsp;</strong>I am the owner of the following
            traces. I shared those with other users.
          </div>
          <div className="card">
            <table
              className="table table-responsive-sm
            table-bordered table-hover table-condensed"
            >
              <thead>
                <tr>
                  <th
                    className="text-center"
                    title="App, computer or organization name"
                  >
                    Name (Web/Mobile App, others)
                  </th>
                  <th
                    className="text-center"
                    title="Username. OK to use email as username"
                  >
                    Username
                  </th>
                  <th
                    className="text-center"
                    title="Anything that helps you to remember."
                  >
                    Password/Hint
                  </th>
                  <th
                    className="text-center"
                    title="I want to share the entry with others."
                  >
                    User Name of Trusted Partner
                  </th>
                </tr>
              </thead>
              <tbody>{dataTraceTrustedPartners}</tbody>
            </table>
          </div>
        </div>
        <div className="panel panel-danger">
          <div className="panel-heading text-center mb-4 mt-4">
            <strong>FROM others:&nbsp;</strong>
            Other users are owners of the following traces. They share those
            with me.
          </div>
          <div className="panel-body">
            <table
              className="table table-responsive-sm table-striped
            table-bordered table-hover table-condensed"
            >
              <thead>
                <tr>
                  <th
                    className="text-center"
                    title="Name of user who is willing to share trace with me"
                  >
                    User Name Of The Owner
                  </th>
                  <th
                    className="text-center"
                    title="Website, computer or organization name"
                  >
                    Name (Web/Mobile App, others)
                  </th>
                  <th
                    className="text-center"
                    title="Username. Some sites use email as username"
                  >
                    Username
                  </th>
                  <th
                    className="text-center"
                    title="Hint or plain password. Anything that helps you to remember"
                  >
                    Password/Hint
                  </th>
                </tr>
              </thead>
              <tbody>{dataAllOnlineTraces}</tbody>
            </table>
          </div>
        </div>
        <p className="text-center">
          <a className="btn btn-primary  w-100" href="/">
            Back to the Main Page
          </a>
        </p>
        <MaxLiProduction />
      </div>
    );
  }
}
export default PartialSharing;
