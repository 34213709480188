import React from "react";
import { Route, Switch } from "react-router-dom";

import Home from "./components/Home";
import Security from "./components/Security";
import Contact from "./components/Contact";

import App from "./App";

import AccessPoint from "./components/AccessPoint";
import ShowAPDetails from "./components/ShowAPDetails";
import UpdateAPDetails from "./components/UpdateAPDetails";

import PartialSharing from "./components/PartialSharing";

import Assign from "./components/Assign";

import ShowDetails from "./components/ShowDetails";
import UpdateDetails from "./components/UpdateDetails";
import CreateOne from "./components/CreateOne";
import Market from "./components/Market";
import Messaging from "./components/Messaging";
import Chart from "./components/Chart";
import AboutMe from "./components/AboutMe";

import CreateAP from "./components/CreateAP";
import UserNameAPName from "./components/UserNameAPName";
import BatchLoading from "./components/BatchLoading";
import SharingIsCaring from "./components/SharingIsCaring";
import Demo from "./components/Demo";

import Login from "./auth/Login";
import Logout from "./auth/Logout";

import Signup from "./auth/Signup";
import NotFound from "./auth/NotFound";
import Settings from "./auth/Settings";
import ChangeEmail from "./auth/ChangeEmail";
import ResetPassword from "./auth/ResetPassword";
import ChangePassword from "./auth/ChangePassword";

import AppliedRoute from "./auth/AppliedRoute";
import AuthenticatedRoute from "./auth/AuthenticatedRoute";
// import UnauthenticatedRoute from "./auth/UnauthenticatedRoute";

export default ({ childProps }) => (
  <Switch>
    <AppliedRoute path="/" exact component={Home} props={childProps} />

    <AppliedRoute
      path="/security"
      exact
      component={Security}
      props={childProps}
    />

    <AppliedRoute path="/demo" exact component={Demo} props={childProps} />

    <AppliedRoute path="/sharingiscaring" exact component={SharingIsCaring} />

    <AppliedRoute
      path="/contact"
      exact
      component={Contact}
      props={childProps}
    />

    <AppliedRoute path="/login" exact component={Login} props={childProps} />

    <AppliedRoute path="/signup" exact component={Signup} props={childProps} />
    <AppliedRoute
      path="/login/reset"
      exact
      component={ResetPassword}
      props={childProps}
    />

    {/* END of AppliedRoute and UnauthenticatedRoute of
     and BEGINNING of AuthenticatedRoute*/}

    <AuthenticatedRoute
      path="/logout"
      exact
      component={Logout}
      props={childProps}
    />

    <AuthenticatedRoute
      path="/settings"
      exact
      component={Settings}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/settings/email"
      exact
      component={ChangeEmail}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/settings/password"
      exact
      component={ChangePassword}
      props={childProps}
    />

    <AuthenticatedRoute
      path="/accesspoint"
      exact
      component={AccessPoint}
      props={childProps}
    />

    <AuthenticatedRoute
      path="/createAP"
      exact
      component={CreateAP}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/showAPDetails/:id"
      exact
      component={ShowAPDetails}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/updateAPDetails/:id"
      exact
      component={UpdateAPDetails}
      props={childProps}
    />

    <AuthenticatedRoute
      path="/partialsharing"
      exact
      component={PartialSharing}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/batchLoading"
      exact
      component={BatchLoading}
      props={childProps}
    />

    <AuthenticatedRoute
      path="/accountLogin"
      exact
      component={App}
      props={childProps}
    />

    <AuthenticatedRoute
      path="/assign/:id"
      exact
      component={Assign}
      props={childProps}
    />

    <AuthenticatedRoute
      path="/showDetails/:id"
      exact
      component={ShowDetails}
      props={childProps}
    />

    <AuthenticatedRoute
      path="/updateDetails/:id"
      exact
      component={UpdateDetails}
      props={childProps}
    />

    <AuthenticatedRoute
      path="/createOne"
      exact
      component={CreateOne}
      props={childProps}
    />

    <AuthenticatedRoute
      path="/market"
      exact
      component={Market}
      props={childProps}
    />

    <AuthenticatedRoute
      path="/messaging"
      exact
      component={Messaging}
      props={childProps}
    />

    <AuthenticatedRoute
      path="/chart"
      exact
      component={Chart}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/aboutMe"
      exact
      component={AboutMe}
      props={childProps}
    />

    {/* should ALWAYS be the one just below the catch all. IMPORTANT   */}
    <AuthenticatedRoute
      path="/:username/:apname"
      exact
      component={UserNameAPName}
      props={childProps}
    />

    {/* Finally, catch all unmatched routes */}
    <Route component={NotFound} />
  </Switch>
);
