import React, { Component } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import Amplify, { API, Auth, graphqlOperation } from "aws-amplify";
import { CopyToClipboard } from "react-copy-to-clipboard";
import clippy from "./images/clippy.svg";
import "./index.css";

import {
  readOnlineTracesWithAFilter,
  deleteOnlineTrace,
  deleteTraceTrustedPartner,
  listTTPBasedOnTraceID,
} from "./components/SomeConstants";

import THead from "./components/THead";
import MaxLiProduction from "./components/MaxLiProduction";

import aws_exports from "./aws-exports";
// specify the location of aws-exports.js file on your project
// Amplify.configure(aws_exports);

Amplify.configure({
  ...aws_exports,
  Analytics: {
    disabled: true,
  },
});

function makeComparator(key, order = "asc") {
  return (a, b) => {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) return 0;

    const aVal = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
    const bVal = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (aVal > bVal) comparison = 1;
    if (aVal < bVal) comparison = -1;

    return order === "desc" ? comparison * -1 : comparison;
  };
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allValues: [],
      clipboardValue: "",
      firedID: null,
      firedColumn: null,
      copied: false,
    };

    this.listOnlineTraces();
    this.getBeginningChars = this.getFirstFewChars.bind(this);
  }

  async componentDidMount() {
    const currentUser = await Auth.currentAuthenticatedUser();

    const userName = currentUser.username;
    console.log(
      "Auth.currentAuthenticatedUser.userame (inside CreateOne.js) is:",
      currentUser.username
    );

    const results = await API.graphql(
      graphqlOperation(readOnlineTracesWithAFilter, {
        userNameOfTheOwner: userName,
      })
    );
    const sortedResult = results.data.listOnlineTraces.items.sort(
      makeComparator("modifiedOn", "desc")
    );

    this.setState({ allValues: sortedResult });
  }

  async handleDeleteAll() {
    const allItems = this.state.allValues;

    for (let item of allItems) {
      await this.handleDelete(item.id);
    }
  }

  async handleDelete(id) {
    const OnlineTraceId = { id: id };
    await API.graphql(graphqlOperation(deleteOnlineTrace, OnlineTraceId));

    // we also need to delete trace_id from TraceTrustedPartner table
    // so that it will not be shown later on.

    const idFilter = {
      filter: {
        trace_id: {
          eq: id,
        },
      },
      limit: 99999,
    };
    const ret = API.graphql(graphqlOperation(listTTPBasedOnTraceID, idFilter));
    const ids = ret.data.listTraceTrustedPartners.items;

    // a single trace_id may appear many times within TraceTrustedPartner
    // we need to find all appearances and delete them.
    // TODO: we also need to delete it from TraceAccessPoint.
    // Yes, we should do. Max Li 2021-03-23

    for (let i = 0; i < ids.length; i++) {
      const idToBeDeleted = ids[0].id;
      await API.graphql(
        graphqlOperation(deleteTraceTrustedPartner, { id: idToBeDeleted })
      );
    }

    this.listOnlineTraces();
  }
  async listOnlineTraces() {
    const currentUser = await Auth.currentAuthenticatedUser();

    const userName = currentUser.username;
    console.log(
      "Auth.currentAuthenticatedUser.userame (inside CreateOne.js) is:",
      currentUser.username
    );

    const objs = await API.graphql(
      graphqlOperation(readOnlineTracesWithAFilter, {
        userNameOfTheOwner: userName,
      })
    );
    const sortedResult = objs.data.listOnlineTraces.items.sort(
      makeComparator("modifiedOn", "desc")
    );
    //console.log("Inside listOnlineTraces objs is:", objs);
    this.setState({ allValues: sortedResult });
  }

  render() {
    const { data, len } = this.getTableInfo();
    return (
      <div className="container-fluid my-4">
        <br />
        <div className="container-fluid text-center">
          <Link className="btn btn-primary mx-1 my-1" to="/createOne">
            Create Online Trace
          </Link>
          <Link className="btn btn-primary mx-1 my-1" to="/batchLoading">
            Batch Loading
          </Link>

          <Link className="btn btn-primary mx-1 my-1" to="/market">
            Market
          </Link>

          <Link className="btn btn-primary mx-1 my-1" to="/messaging">
            Messaging
          </Link>

          <Link className="btn btn-primary mx-1 my-1" to="/chart">
            Chart
          </Link>

          <Link className="btn btn-primary mx-1 my-1" to="/aboutMe">
            About Me
          </Link>

          <button
            hidden
            type="button"
            className="btn btn-danger mx-1 my-1"
            onClick={() => {
              if (
                window.confirm(
                  "Do you REALLY want to delete ALL Records??? Think Twice."
                )
              ) {
                /* very important. Must create a const, then call it */
                /* Otherwise, it will not work */
                const removeAll = this.handleDeleteAll.bind(this);
                removeAll();
              }
            }}
          >
            Delete ALL!!!
          </button>
        </div>
        <br />
        <div className="container-fluid">
          <p className="text-center">
            Hover over (Mouse over) the cell and click on &nbsp;
            <img src={clippy} alt="Copy to clipboard" width="20px" />
            &nbsp; for copying
          </p>
          <p className="text-center">
            Total number of entries: <strong>{len}</strong>
          </p>
          <table
            className="table table-responsive-sm table-striped
        table-bordered table-hover table-condensed"
          >
            <THead />
            <tbody>{data}</tbody>
          </table>
        </div>
        <MaxLiProduction />
      </div>
    );
  }

  getFirstFewChars(input) {
    // console.log("input inside is: ", input);
    let aCopy = JSON.parse(JSON.stringify(input));

    const arr = aCopy.split("");
    let ret = "";
    // console.log("arr.length is: ", arr.length);

    if (arr.length >= 17) {
      for (let i = 0; i < 17; i++) {
        ret += arr[i];
      }
      return ret + "...";
    } else {
      return aCopy;
    }
  }
  getTableInfo() {
    const newValues = [];
    const len = this.state.allValues.length;
    for (const one of this.state.allValues) {
      // console.log("one is: ", one);
      newValues.push({
        id: one.id,
        comment: one.comment,
        createdOn: one.createdOn,
        email: one.email,

        shortHintForAccess: this.getFirstFewChars(one.hintForAccess),
        longHintForAccess: one.hintForAccess,

        isPrivate: one.isPrivate,
        modifiedOn: one.modifiedOn,
        monthlyCharge: one.monthlyCharge,

        shortName: this.getFirstFewChars(one.name),
        longName: one.name,

        securityQuestions: one.securityQuestions,

        shortUserName: this.getFirstFewChars(one.userName),
        longUserName: one.userName,
      });
    }

    const data = newValues.map((item, i) => (
      <tr key={i}>
        <td key={i + 1} className="text-center ">
          <span title={item.longName}>{item.shortName} &nbsp;</span>

          {item.shortName !== " " ? (
            <CopyToClipboard
              text={item.longName}
              onCopy={() =>
                this.setState({
                  copied: true,
                  firedID: item.id,
                  firedColumn: "Name",
                })
              }
            >
              <button
                className="none-class"
                onClick={() => {
                  setTimeout(() => {
                    this.setState({ copied: false });
                  }, 1000);
                }}
              >
                <img src={clippy} alt="Copy to clipboard" width="20px" />
              </button>
            </CopyToClipboard>
          ) : null}
          <div>
            {this.state.copied &&
            item.id === this.state.firedID &&
            this.state.firedColumn === "Name" ? (
              <span style={{ color: "white", backgroundColor: "black" }}>
                copied!
              </span>
            ) : null}
          </div>
        </td>

        <td className="text-center" key={i + 2}>
          <span title={item.longUserName}>{item.shortUserName}&nbsp;</span>

          {item.shortUserName !== " " ? (
            <CopyToClipboard
              text={item.longUserName}
              onCopy={() =>
                this.setState({
                  copied: true,
                  firedID: item.id,
                  firedColumn: "UserName",
                })
              }
            >
              <button
                className="none-class"
                onClick={() => {
                  setTimeout(() => {
                    this.setState({ copied: false });
                  }, 1000);
                }}
              >
                <img src={clippy} alt="Copy to clipboard" width="20px" />
              </button>
            </CopyToClipboard>
          ) : null}
          <div>
            {this.state.copied &&
            item.id === this.state.firedID &&
            this.state.firedColumn === "UserName" ? (
              <span style={{ color: "white", backgroundColor: "black" }}>
                copied!
              </span>
            ) : null}
          </div>
        </td>
        <td className="text-center" key={i + 3}>
          <span title={item.longHintForAccess}>
            {item.shortHintForAccess}&nbsp;
          </span>
          {item.shortHintForAccess !== " " ? (
            <CopyToClipboard
              text={item.longHintForAccess}
              onCopy={() =>
                this.setState({
                  copied: true,
                  firedID: item.id,
                  firedColumn: "HintForAccess",
                })
              }
            >
              <button
                className="none-class"
                onClick={() => {
                  setTimeout(() => {
                    this.setState({ copied: false });
                  }, 1000);
                }}
              >
                <img src={clippy} alt="Copy to clipboard" width="20px" />
              </button>
            </CopyToClipboard>
          ) : null}
          <div>
            {this.state.copied &&
            item.id === this.state.firedID &&
            this.state.firedColumn === "HintForAccess" ? (
              <span style={{ color: "white", backgroundColor: "black" }}>
                copied!
              </span>
            ) : null}
          </div>
        </td>
        <td className="text-center" key={i + 4}>
          {item.monthlyCharge}
        </td>

        <td className="text-center">
          <Link
            className="btn btn-primary mx-1 my-1"
            key={i + 5}
            to={`/showDetails/${item.id}`}
          >
            Details
          </Link>

          <Link
            className="btn btn-info mx-1 my-1"
            key={i + 6}
            to={`/updateDetails/${item.id}`}
          >
            Update
          </Link>

          <Link
            className="btn btn-primary mx-1 my-1"
            key={i + 7}
            to={`/assign/${item.id}`}
          >
            Assign
          </Link>

          <button
            key={i + 8}
            type="button"
            className="btn btn-danger mx-1 my-1 "
            onClick={() => {
              if (window.confirm("Do you REALLY want to delete the record?")) {
                /* very important. Must create a const, then call it */
                /* Otherwise, it will NOT work */
                const removeToCollection = this.handleDelete.bind(
                  this,
                  item.id
                );
                removeToCollection();
              }
            }}
          >
            Delete
          </button>
        </td>
      </tr>
    ));

    return { data, len };
  }
}
export default App;
