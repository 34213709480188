import { Component } from "react";
import { Auth } from "aws-amplify";

import "./Logout.css";

export default class Logout extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  // async componentDidMount() {
  async componentDidMount() {
    await Auth.signOut();

    this.props.userHasAuthenticated(false);

    this.props.history.push("/login");
    // this.props.history.push("/login");
  }
}
