import React, { Component } from "react";
import {
  listAPIDByAPName,
  listTraceAccessPointByAPID,
  listOTPublicAttributes,
} from "./SomeConstants";
import { API, graphqlOperation } from "aws-amplify";

class UserNameAPName extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allValues: [],
      hasRealData: true,
    };

    this.getThemAndSetState(
      props.match.params.username,
      props.match.params.apname
    );
  }

  async getThemAndSetState(username, apname) {
    /* get ap_id from apname. get an array of trace_id based on ap_id. 
    Table: TraceAccessPoint.
      from the OnlineTraces table, get details about the trace.
       trace_id must match. 
      userNameOfTheOwner also must match*/

    let stateArray = [];

    const apid_from_name = await API.graphql(
      graphqlOperation(listAPIDByAPName, { name: apname })
    );

    const id_array_only = apid_from_name.data.listAccessPoints.items;

    if (id_array_only[0]) {
      const apid = id_array_only[0].id;

      const trace_ids = await API.graphql(
        graphqlOperation(listTraceAccessPointByAPID, {
          access_point_id: apid,
        })
      );

      const trace_ids_result = trace_ids.data.listTraceAccessPoints.items;

      for (let i = 0; i < trace_ids_result.length; i++) {
        const trace_id = trace_ids_result[i].trace_id;
        const onlineTraces = await API.graphql(
          graphqlOperation(listOTPublicAttributes, {
            trace_id: trace_id,
            userNameOfTheOwner: username,
          })
        );
        const items = onlineTraces.data.listOnlineTraces.items;
        if (items) {
          for (let item of items) {
            stateArray.push({
              name: item.name,
              userName: item.userName,
              hintForAccess: item.hintForAccess,
              comment: item.comment,
            });
          }
        }
      }
    }
    this.setState({ allValues: stateArray });
    if (
      username === "updateDetails" ||
      username === "updateAPDetails" ||
      username === "showAPDetails" ||
      username === "showDetails" ||
      username === "assign"
    ) {
      this.setState({ hasRealData: false });
    } else {
      this.setState({ hasRealData: true });
    }
  }

  render() {
    const data = this.getTableData();

    return (
      <React.Fragment>
        {this.state.hasRealData ? (
          <table
            className="table table-responsive-sm table-striped
        table-bordered table-hover table-condensed"
          >
            <thead>
              <tr className="text-center">
                <th>APP (Web, Mobile or Other) Name</th>
                <th>User Name</th>
                <th>Hint For Access</th>
                <th>Comment</th>
              </tr>
            </thead>
            <tbody>{data}</tbody>
          </table>
        ) : null}
      </React.Fragment>
    );
  }

  getTableData() {
    const data = this.state.allValues.map((item, i) => (
      <tr key={i} className="">
        <td className="text-center" key={i + 1}>
          {item.name}
        </td>
        <td className="text-center" key={i + 2}>
          {item.userName}
        </td>
        <td className="text-center" key={i + 3}>
          {item.hintForAccess}
        </td>
        <td className="text-center" key={i + 4}>
          {item.comment}
        </td>
      </tr>
    ));
    return data;
  }
}

export default UserNameAPName;
