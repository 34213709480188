import React, { Component } from "react";
import {
  listIDByTraceIDAccessPointID,
  listAccessPointsWithAFilter,
  listTraceTrustedPartnersWithAFilter,
  createTraceTrustedPartner,
  deleteTraceTrustedPartner,
  createTraceAccessPoint,
  deleteTraceAccessPoint,
  listDuplicateUserInfos,
  getOnlineTrace,
} from "./SomeConstants";
import { API, graphqlOperation, Auth } from "aws-amplify";

class Assign extends Component {
  constructor(props) {
    super(props);

    this.state = {
      trace_id: props.match.params.id,
      allAccessPoints: [],

      APIDsCheckedOrNot: {},

      toBeCreatedTraceAccessPoint: [],
      toBeDeletedTraceAccessPoint: [],

      allTraceTrustedPartners: [],
      uncheckedTraceTrustedPartners: [],
      validUserName: "",
      loggedUserName: "",
      hasError: false,
      errorMessage: "",
    };

    this.getThemAndSetState(props.match.params.id);

    this.handlevalidUserNameChange = this.handlevalidUserNameChange.bind(this);

    this.handleCheckAccessPointChange = this.handleCheckAccessPointChange.bind(
      this
    );
    this.handleCheckTrustedPartnerChange = this.handleCheckTrustedPartnerChange.bind(
      this
    );

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async getThemAndSetState(passedTraceID) {
    const user = await Auth.currentAuthenticatedUser();

    const userName = user.username;
    this.setState({ loggedUserName: userName });
    const result1 = await API.graphql(
      graphqlOperation(listAccessPointsWithAFilter, {
        userNameOfTheOwner: userName,
      })
    );

    const allAccessPoints = result1.data.listAccessPoints.items;
    this.setState({ allAccessPoints });

    let newObj = JSON.parse(JSON.stringify(allAccessPoints));
    let statuses = {};
    console.log("this.state.allAccessPoints is:", this.state.allAccessPoints);
    console.log("newObj is:", newObj);
    console.log("newObj.length is:", newObj.length);
    for (let i = 0; i < newObj.length; i++) {
      const trace_id = this.state.trace_id;
      const ap_id = newObj[i].id;

      const ret = await this.checkTraceAccessPoint(trace_id, ap_id);
      console.log("Returned value from this.checkTraceAccessPoint is:", ret);

      statuses[ap_id] = ret;
    }

    this.setState({ APIDsCheckedOrNot: statuses });

    const result2 = await API.graphql(
      graphqlOperation(listTraceTrustedPartnersWithAFilter, {
        trace_id: passedTraceID,
      })
    );

    const allTraceTrustedPartners = result2.data.listTraceTrustedPartners.items;
    this.setState({ allTraceTrustedPartners: allTraceTrustedPartners });
  }

  handleCheckAccessPointChange(event) {
    // console.log("event.target.value is:", event.target.value);
    // console.log("event.target.checked is:", event.target.checked);
    // console.log("event.target is:", event.target);
    // console.log("event.target.name is:", event.target.name);

    if (event.target.checked === true && event.target.name === "AccessPoint") {
      const addOne = this.state.toBeCreatedTraceAccessPoint.concat(
        event.target.value
      );

      this.setState({
        toBeCreatedTraceAccessPoint: addOne,
      });
    }

    if (event.target.checked === false && event.target.name === "AccessPoint") {
      const deleteOne = this.state.toBeDeletedTraceAccessPoint.concat(
        event.target.value
      );

      this.setState({
        toBeDeletedTraceAccessPoint: deleteOne,
      });
    }

    let newObj = JSON.parse(JSON.stringify(this.state.APIDsCheckedOrNot));

    newObj[event.target.value] = !newObj[event.target.value];
    this.setState({ APIDsCheckedOrNot: newObj });

    // this.state.APIDsCheckedOrNot[event.target.value] = !this.state
    //   .APIDsCheckedOrNot[event.target.value];
  }
  handleCheckTrustedPartnerChange(event) {
    if (
      event.target.checked === false &&
      event.target.name === "TrustedPartner"
    ) {
      const addOne = this.state.uncheckedTraceTrustedPartners.concat(
        event.target.value
      );
      this.setState({
        uncheckedTraceTrustedPartners: addOne,
      });
    }
    if (
      event.target.checked === true &&
      event.target.name === "TrustedPartner"
    ) {
      const deleteOne = this.state.uncheckedTraceTrustedPartners.filter(
        (item, _) => item !== event.target.value
      );

      this.setState({
        uncheckedTraceTrustedPartners: deleteOne,
      });
    }
  }

  async handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    console.log(
      "Inside handleSubmit. toBeCreatedTraceAccessPoint is*" +
        this.state.toBeCreatedTraceAccessPoint +
        "*"
    );

    let created = JSON.parse(
      JSON.stringify(this.state.toBeCreatedTraceAccessPoint)
    );

    let deleted = JSON.parse(
      JSON.stringify(this.state.toBeDeletedTraceAccessPoint)
    );

    let i = 0;
    let len = created.length;

    while (i < len) {
      let deletedIndex = deleted.indexOf(created[i]);
      if (deletedIndex >= 0) {
        created.splice(deletedIndex, 1);
        deleted.splice(deletedIndex, 1);
        len = created.length;
      } else {
        // increase i ONLY when the element is NOT found.
        i += 1;
      }
    }

    console.log("created is:*", created, "*");
    console.log("deleted is:*", deleted, "*");

    for (let deletedOne of deleted) {
      // need to find id first, before we can
      // delete it. We can ONLY delete by ID.
      const result = await API.graphql(
        graphqlOperation(listIDByTraceIDAccessPointID, {
          trace_id: this.state.trace_id,
          access_point_id: deletedOne,
        })
      );
      let data = "";
      if (result.data.listTraceAccessPoints.items[0])
        data = result.data.listTraceAccessPoints.items[0].id;

      if (data !== "") {
        await API.graphql(
          graphqlOperation(deleteTraceAccessPoint, {
            id: data,
          })
        );
      }
    }

    for (let createdOne of created) {
      const obj = {
        trace_id: this.state.trace_id,
        access_point_id: createdOne,
        DT_established: new Date().toISOString(),
      };
      await API.graphql(graphqlOperation(createTraceAccessPoint, obj));
    }

    for (let uTTP of this.state.uncheckedTraceTrustedPartners) {
      await API.graphql(
        graphqlOperation(deleteTraceTrustedPartner, { id: uTTP })
      );
    }

    console.log(
      "this.state.allTraceTrustedPartners is: ",
      this.state.allTraceTrustedPartners
    );

    console.log(
      "this.state.uncheckedTraceTrustedPartners is: ",
      this.state.uncheckedTraceTrustedPartners
    );

    if (this.state.validUserName !== "") {
      // check the DuplicateUserInfo table. It must exist. Otherwise
      // the user name does not exist error.
      if (this.state.loggedUserName === this.state.validUserName) {
        this.setState({ hasError: true });
        this.setState({
          errorMessage:
            "You cannot assign the trace to yourself. Please try again.",
        });
      } else {
        const duplicateUserInfoFilter = {
          filter: {
            username: {
              eq: this.state.validUserName.trim(),
            },
          },
          limit: 99999,
        };
        const {
          data: {
            listDuplicateUserInfos: { items: ret },
          },
        } = await API.graphql(
          graphqlOperation(listDuplicateUserInfos, duplicateUserInfoFilter)
        );
        if (ret.length === 1) {
          this.setState({ hasError: false });
        } else {
          this.setState({ hasError: true });
          this.setState({
            errorMessage: "Invalid Username. Please try again.",
          });
        }
      }
      if (!this.state.hasError) {
        const result = await API.graphql(
          graphqlOperation(getOnlineTrace, { id: this.state.trace_id })
        );
        const singleRow = result.data.getOnlineTrace;
        const name = singleRow.name;
        const userName = singleRow.userName;
        const hintForAccess = singleRow.hintForAccess;

        const obj = {
          trace_id: this.state.trace_id,
          name: name,
          userName: userName,
          hintForAccess: hintForAccess,
          sender_username: this.state.loggedUserName,
          trusted_partner_username: this.state.validUserName,
          // use the current date time
          DT_established: new Date().toISOString(),
        };

        await API.graphql(graphqlOperation(createTraceTrustedPartner, obj));
        console.log("done with create TraceTrusted Partner. Into a database.");
        this.props.history.push("/accountLogin");
      } else {
        console.log("has Error. Should display here");
      }
    }
  }

  handlevalidUserNameChange(event) {
    this.setState({ validUserName: event.target.value });
  }

  render() {
    const dataTraceAccessPoints = this.getTraceAccessPoints();

    const dataTraceTrustedPartners = this.getTraceTrustedPartners();
    return (
      <div className="container-fluid my-4">
        <div>
          <h3 className="text-center p-3">
            Assign to: (public) access points and/or (private) trusted partners
          </h3>
        </div>
        <form onSubmit={this.handleSubmit}>
          <div className="card">
            <div className="card-title display-4 text-center">
              Associate the trace with (public) access points
            </div>
            <div className="card-body">
              <p className="text-left">
                Note: If the entry has been checked already, it means that the
                trace has been assigned to the access point. You can "uncheck"
                it if you don't want to associate the trace with the access
                point anymore. For example, if a school year is over, you can
                cut off the relationship between a course trace and an access
                point for a group of students.
              </p>
              <table
                className="table table-responsive-sm table-striped
        table-bordered table-hover table-condensed"
              >
                <thead>
                  <tr className="text-center">
                    {/* <th>Check to select</th> */}
                    <th>ID</th>
                    <th>Name</th>
                    <th>Purpose</th>
                    <th>Created on</th>
                  </tr>
                </thead>

                <tbody>{dataTraceAccessPoints}</tbody>
              </table>
            </div>
          </div>

          <div className="card p-1 mt-4">
            <div className="card-title display-4 text-center">
              Associate the trace with trusted partners
            </div>
            <div className="card-body">
              <p>
                The following are trusted partner(or user) that you have shared
                the trace entry with. You can check or uncheck.
              </p>
              <table
                className="table table-responsive-sm table-striped
        table-bordered table-hover table-condensed"
              >
                <thead>
                  <tr className="text-center">
                    <th>Check to select</th>
                    <th>User Name</th>
                    <th>Date/Time Established</th>
                  </tr>
                </thead>
                <tbody>{dataTraceTrustedPartners}</tbody>
              </table>

              {this.state.hasError ? (
                <p className="color_red">
                  Error Found: &nbsp;
                  {this.state.errorMessage}
                </p>
              ) : null}

              <label>Please enter ONE valid username: &nbsp;</label>
              <input
                name="trusted_partner_usernames"
                id="trusted_partner_usernames"
                type="text"
                size="70"
                placeholder="Just one VALID username. No more."
                maxLength="255"
                value={this.state.validUserName}
                onChange={this.handlevalidUserNameChange}
              />
            </div>
          </div>

          <input
            className="btn btn-success  w-100 text-center"
            type="submit"
            value="Associate with access points and trusted partners"
          />
          <p className="text-center mt-4">&nbsp; OR &nbsp;</p>
          <a
            href="/accountLogin"
            className="btn btn-warning  w-100 text-center"
          >
            Cancel and go back to the trace list
          </a>
        </form>
      </div>
    );
  }

  async checkTraceAccessPoint(trace_id, access_point_id) {
    const result = await API.graphql(
      graphqlOperation(listIDByTraceIDAccessPointID, {
        trace_id: trace_id,
        access_point_id: access_point_id,
      })
    );

    console.log("result is:", result);
    const data = result.data.listTraceAccessPoints.items;
    if (data.length !== 0) return true;
    else return false;
  }

  getTraceAccessPoints() {
    const data = this.state.allAccessPoints.map((item, i) => (
      <tr key={i + 20}>
        <td className="text-center" key={i + 21}>
          <input
            type="Checkbox"
            name="AccessPoint"
            value={item.id}
            checked={this.state.APIDsCheckedOrNot[item.id]}
            onChange={this.handleCheckAccessPointChange}
          />
        </td>
        {/* <td className="text-center" key={i + 22}>
          {item.id}
        </td> */}
        <td className="text-center" key={i + 23}>
          {item.name}
        </td>
        <td className="text-center" key={i + 24}>
          {item.purpose}
        </td>
        <td className="text-center" key={i + 25}>
          {item.creationDT}
        </td>
      </tr>
    ));
    // console.log("data is: ", data);
    return data;
  }

  getTraceTrustedPartners() {
    const data = this.state.allTraceTrustedPartners.map((item, i) => (
      <tr key={i + 31}>
        <td className="text-center" key={i + 32}>
          <input
            type="Checkbox"
            name="TrustedPartner"
            value={item.id}
            defaultChecked
            onChange={this.handleCheckTrustedPartnerChange}
          />
        </td>
        {/* <td className="text-center" key={i + 33}>
            {item.trace_id}
          </td> */}
        <td className="text-center" key={i + 34}>
          {item.trusted_partner_username}
        </td>
        <td className="text-center" key={i + 35}>
          {item.DT_established}
        </td>
      </tr>
    ));
    return data;
  }
}

export default Assign;
