import React, { Component } from "react";

import { Auth, API, graphqlOperation } from "aws-amplify";
import "../index.css";

import { createOnlineTrace } from "./SomeConstants";
import MaxLiProduction from "./MaxLiProduction";
import ReactLoading from "react-loading";

class BatchLoading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      busyLoading: false,

      textareaValue: " ",
      placeHolder:
        "netflix.com, steve123, best friend's name, 16.99\nfacebook.com, max, cat name, 0.0",
      hasError: false,
      errorMessage: " ",
    };
    this.handleTextareaChange = this.handleTextareaChange.bind(this);

    this.handleTextareaSubmit = this.handleTextareaSubmit.bind(this);
    this.handleFileUploadSubmit = this.handleFileUploadSubmit.bind(this);
  }

  handleTextareaChange(event) {
    this.setState({ textareaValue: event.target.value });
  }

  buildTheStr(num) {
    let ret = "";
    for (let i = 0; i < num; i++) {
      ret += "\n";
    }
    return ret;
  }
  async handleTextareaSubmit(event) {
    this.setState({ busyLoading: true });

    event.preventDefault();
    event.stopPropagation();

    const currentUser = await Auth.currentAuthenticatedUser();
    const loggedUserName = currentUser.username;

    const rawStr = this.state.textareaValue;
    console.log("rawStr is: *", rawStr, "*");

    console.log("rawStr.trim() is: *", rawStr.trim(), "*");

    console.log("rawStr.trim().length is: *", rawStr.trim().length, "*");

    if (!rawStr || rawStr.trim().length === 0) {
      this.setState({
        hasError: true,
        errorMessage: "The input has no meaningful content. Try again.",
      });
    } else {
      /* https://www.textfixer.com/tutorials/javascript-line-breaks.php  */
      const carriageReturn2LineFeed = rawStr.replace(/(\r)/gm, "\n");

      let singleNewline = carriageReturn2LineFeed;

      let len = singleNewline.length;

      for (let i = len; i >= 2; i--) {
        const lineFeeds = this.buildTheStr(i);
        let index = singleNewline.indexOf(lineFeeds);
        while (index !== -1) {
          singleNewline =
            singleNewline.slice(0, index) +
            "\n" +
            singleNewline.slice(index + i);

          index = singleNewline.indexOf(lineFeeds);
        }
      }

      const newline2Comma = singleNewline.replace(/(\n)/gm, ",");

      const strArray = newline2Comma.split(",");

      // console.log("before shift and pop, strArray is: ", strArray);

      while (strArray[0].trim() === "") {
        strArray.shift();
      }

      while (strArray[strArray.length - 1].trim() === "") {
        strArray.pop();
      }

      // console.log("strArray is: ", strArray);

      /* count the number of commas (,). If not
       a multiple of 4, error message */

      const multipleOf4 = strArray.length;
      console.log("multipleOf4 is: ", multipleOf4);

      if (multipleOf4 % 4 !== 0) {
        this.setState({
          hasError: true,
          errorMessage:
            "Wrong format. Double check commas. Delete unnecessary empty spaces and empty lines",
        });
      } else {
        for (let i = 0; i < multipleOf4; i += 4) {
          let appName = strArray[i].trim();
          if (appName === "") {
            appName = " ";
          }
          let userName = strArray[i + 1].trim();
          if (userName === "") {
            userName = " ";
          }
          let hint = strArray[i + 2].trim();
          if (hint === "") {
            hint = " ";
          }

          let money = 0.0;
          const moneyStr = strArray[i + 3].trim();
          if (moneyStr !== "") {
            const tempMoney = parseFloat(moneyStr);
            if (!isNaN(tempMoney)) {
              money = tempMoney;
            }
          }
          const thisMoment = new Date();

          const obj = {
            name: appName,
            monthlyCharge: money,
            userName: userName,
            hintForAccess: hint,
            isPrivate: true,
            createdOn: thisMoment.toISOString(),
            modifiedOn: thisMoment.toISOString(),
            email: " ",
            comment: " ",
            securityQuestions: " ",
            userNameOfTheOwner: loggedUserName,
          };
          console.log("obj is: ", obj);
          await API.graphql(graphqlOperation(createOnlineTrace, obj));
          console.log("done with create Online Traces. Into a database.");
        }
        this.props.history.push("/accountLogin");
      }
    }
  }

  handleFileUploadSubmit(event) {
    /* to be implemented by Nate and Bryce*/

    event.preventDefault();
    event.stopPropagation();
  }

  render() {
    return (
      <div className="container-fluid my-4">
        <div className="text-center">
          {this.state.busyLoading ? (
            <div className="d-inline align-middle">
              <ReactLoading
                type="spin"
                color="#0000FF"
                height="40px"
                width="40px"
              />
            </div>
          ) : null}
          {this.state.hasError ? (
            <p className="color_red d-inline">
              Error Found: &nbsp;
              {this.state.errorMessage}
            </p>
          ) : null}

          <h2>Batch Loading</h2>
          <form id="form1" onSubmit={this.handleTextareaSubmit}>
            <div>
              <p>Option 1: Copy/paste into a textarea</p>
              <h6 className="mb-4">
                IMPORTANT: comma(,) is required. It is not allowed at the very
                end.
              </h6>
              <textarea
                id="textarea1"
                placeholder={this.state.placeHolder}
                /* value={this.state.textareaValue} */
                rows="8"
                cols="80"
                onChange={this.handleTextareaChange}
              />
              <div className="mb-1 mt-1">
                <input
                  className="btn btn-primary mt-2"
                  type="submit"
                  value="Import data in textarea"
                />
              </div>
            </div>
          </form>
          <form id="form2" onSubmit={this.handleFileUploadSubmit}>
            <div>
              <p>Option 2: Upload a CSV file</p>
              <input type="file" id="fileInput" />
              <div className="mb-1 mt-1">
                <input
                  className="btn btn-primary"
                  type="submit"
                  value="Process data in the file(TODOLIST)"
                />
              </div>
            </div>
          </form>
          <MaxLiProduction />
        </div>
      </div>
    );
  }
}
export default BatchLoading;
