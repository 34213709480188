import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { FormGroup, FormControl } from "react-bootstrap";
import LoaderButton from "./LoaderButton";
import "./ChangePassword.css";

export default class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      oldPassword: "",
      isChanging: false,
      confirmPassword: "",
      hasError: false,
      errorMessage: ""
    };
  }

  validateForm() {
    return (
      this.state.oldPassword.length > 0 &&
      this.state.password.length > 0 &&
      this.state.password === this.state.confirmPassword
    );
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleChangeClick = async event => {
    event.preventDefault();

    this.setState({ isChanging: true });

    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(
        currentUser,
        this.state.oldPassword,
        this.state.password
      );

      // this.props.history.push("/settings");
      this.props.history.push("/accountLogin");
    } catch (e) {
      this.setState({ hasError: true });
      this.setState({ errorMessage: e.message });
      // alert(e.message);
      this.setState({ isChanging: false });
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="ChangePassword">
          {this.state.hasError ? (
            <p className="color_red">
              Error Found: &nbsp;
              {this.state.errorMessage}
            </p>
          ) : null}

          <form onSubmit={this.handleChangeClick}>
            <FormGroup controlId="oldPassword">
              <span>Old Password</span>
              <FormControl
                type="password"
                onChange={this.handleChange}
                value={this.state.oldPassword}
              />
            </FormGroup>
            <hr />
            <FormGroup controlId="password">
              <span>Proposed Password</span>
              <FormControl
                type="password"
                value={this.state.password}
                onChange={this.handleChange}
              />
            </FormGroup>
            <FormGroup controlId="confirmPassword">
              <span>Confirm Proposed Password</span>
              <FormControl
                type="password"
                onChange={this.handleChange}
                value={this.state.confirmPassword}
              />
            </FormGroup>
            <LoaderButton
              block
              type="submit"
              text="Change Password"
              loadingText="Changing…"
              disabled={!this.validateForm()}
              isLoading={this.state.isChanging}
            />
          </form>
        </div>
      </React.Fragment>
    );
  }
}
