import React, { Component } from "react";
import { Auth, API, graphqlOperation } from "aws-amplify";
import "../index.css";

import { listAboutMes, updateAboutMe } from "./SomeConstants";

import MaxLiProduction from "./MaxLiProduction";

class AboutMe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      fullName: "",
      preferredName: "",
      DOB: "",
      places: "",
      schools: "",
      books: "",
      movies: "",
      music: "",
      sports: "",
      hasError: false,
      errorMessage: "",
    };
    this.handleFullNameChange = this.handleFullNameChange.bind(this);
    this.handlePreferredNameChange = this.handlePreferredNameChange.bind(this);
    this.handleDOBChange = this.handleDOBChange.bind(this);
    this.handlePlacesChange = this.handlePlacesChange.bind(this);
    this.handleSchoolsChange = this.handleSchoolsChange.bind(this);
    this.handleBooksChange = this.handleBooksChange.bind(this);
    this.handleMoviesChange = this.handleMoviesChange.bind(this);
    this.handleMusicChange = this.handleMusicChange.bind(this);
    this.handleSportsChange = this.handleSportsChange.bind(this);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  async componentDidMount() {
    console.log("12345 Calling componentDidMount of AboutMe.js");

    const currentUser = await Auth.currentAuthenticatedUser();
    const loggedUserName = currentUser.username;
    console.log("loggedUserName is:   ", loggedUserName);

    console.log("point 2");

    const aboutMeFilter = {
      filter: {
        userName: {
          eq: loggedUserName,
        },
      },
      limit: 99999,
    };

    console.log("aboutMeFilter is: ", aboutMeFilter);

    const {
      data: {
        listAboutMes: { items: ret },
      },
    } = await API.graphql(graphqlOperation(listAboutMes, aboutMeFilter));
    console.log("      returned object ret is: ", ret);
    console.log("point 3    ");

    console.log("   ret.length is:    ", ret.length);

    if (ret.length === 1) {
      console.log("ret[0] is: ", ret[0]);
      console.log("ret[0].id is: ", ret[0].id);

      // find the record. Need to update existing one.
      this.setState({ id: ret[0].id === " " ? "" : ret[0].id });
      this.setState({
        fullName: ret[0].fullName === " " ? "" : ret[0].fullName,
      });
      this.setState({
        preferredName: ret[0].preferredName === " " ? "" : ret[0].preferredName,
      });
      this.setState({ DOB: ret[0].DOB === " " ? "" : ret[0].DOB });
      this.setState({ places: ret[0].places === " " ? "" : ret[0].places });
      this.setState({ schools: ret[0].schools === " " ? "" : ret[0].schools });
      this.setState({ books: ret[0].books === " " ? "" : ret[0].books });
      this.setState({ movies: ret[0].movies === " " ? "" : ret[0].movies });
      this.setState({ music: ret[0].music === " " ? "" : ret[0].music });
      this.setState({ sports: ret[0].sports === " " ? "" : ret[0].sports });
      this.setState({
        userName: ret[0].userName === " " ? "" : ret[0].userName,
      });
    } else {
      console.log(
        "Something is wrong inside Signup.js. Cannot get the record for AboutMe"
      );
    }
  }
  handleFullNameChange(event) {
    this.setState({ fullName: event.target.value });
  }
  handlePreferredNameChange(event) {
    this.setState({ preferredName: event.target.value });
  }
  handleDOBChange(event) {
    this.setState({ DOB: event.target.value });
  }
  handlePlacesChange(event) {
    this.setState({ places: event.target.value });
  }
  handleSchoolsChange(event) {
    this.setState({ schools: event.target.value });
  }
  handleBooksChange(event) {
    this.setState({ books: event.target.value });
  }
  handleMoviesChange(event) {
    this.setState({ movies: event.target.value });
  }
  handleMusicChange(event) {
    this.setState({ music: event.target.value });
  }
  handleSportsChange(event) {
    this.setState({ sports: event.target.value });
  }
  handleUserNameChange(event) {
    this.setState({ userName: event.target.value });
  }

  async handleFormSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    console.log("Inside handleFormSubmit of AboutMe.js");

    const currentUser = await Auth.currentAuthenticatedUser();
    const loggedUserName = currentUser.username;
    this.setState({ userName: loggedUserName });

    if (this.state.fullName === "") {
      this.setState({ fullName: " " });
    }
    if (this.state.preferredName === "") {
      this.setState({ preferredName: " " });
    }
    if (this.state.DOB === "") {
      this.setState({ DOB: " " });
    }
    if (this.state.places === "") {
      this.setState({ places: " " });
    }
    if (this.state.schools === "") {
      this.setState({ schools: " " });
    }
    if (this.state.books === "") {
      this.setState({ books: " " });
    }
    if (this.state.movies === "") {
      this.setState({ movies: " " });
    }
    if (this.state.music === "") {
      this.setState({ music: " " });
    }
    if (this.state.sports === "") {
      this.setState({ sports: " " });
    }
    if (this.state.userName === "") {
      this.setState({ userName: " " });
    }

    const obj = {
      id: this.state.id,
      fullName: this.state.fullName,
      preferredName: this.state.preferredName,
      DOB: this.state.DOB,
      places: this.state.places,
      schools: this.state.schools,
      books: this.state.books,
      movies: this.state.movies,
      music: this.state.music,
      sports: this.state.sports,
      userName: this.state.userName,
    };

    console.log("obj before updateAboutMe is: ", obj);

    await API.graphql(graphqlOperation(updateAboutMe, obj));

    this.props.history.push("/accountLogin");
  }

  render() {
    return (
      <div className="container-fluid my-4">
        <h2>all about ME ME ME</h2>
        <h5 className="my-4">
          <div className="my-3">
            <kbd>
              <strong>Why bother?</strong>
            </kbd>
          </div>
          <div className="card">
            If you want others (possibly total strangers) to give you account
            information, you need to make others comfortable. By revealing
            truthful personal information, others could reduce guard againt you.
          </div>

          <div className="card my-2">
            For example, if you tell the world that you attended the University
            of Florida, UF gators more than likely would want to share
            information with you.
          </div>

          <div className="my-3">
            <samp>Human psychology at work!</samp>
          </div>
        </h5>
        <form id="formAboutMe" onSubmit={this.handleFormSubmit}>
          <div className="form-group">
            <div className="mb-1">
              <label htmlFor="fullName">Full Name: &nbsp;</label>
              <input
                className="form-control"
                id="fullName"
                size="80"
                placeholder="Full Name."
                value={this.state.fullName}
                onChange={this.handleFullNameChange}
              />
            </div>

            <div className="mb-1">
              <label htmlFor="preferredName">Preferred Name: &nbsp;</label>
              <input
                className="form-control"
                id="preferredName"
                size="80"
                placeholder="Preferred Name."
                value={this.state.preferredName}
                onChange={this.handlePreferredNameChange}
              />
            </div>
            <div className="mb-1">
              <label htmlFor="DOB">Date of Birth YYYY-MM-DD: &nbsp;</label>
              <input
                className="form-control"
                id="DOB"
                size="80"
                placeholder="DOB (Date of Birth. Format: YYYY-MM-DD)"
                pattern="(18|19|20|21|22)[0-9]{2}-[0-9]{2}-[0-9]{2}"
                value={this.state.DOB}
                onChange={this.handleDOBChange}
              />
            </div>

            <div className="mb-1">
              <label htmlFor="places">Places: &nbsp;</label>
              <input
                className="form-control"
                id="places"
                size="80"
                placeholder="Places that you were born, have lived, worked, visited..."
                value={this.state.places}
                onChange={this.handlePlacesChange}
              />
            </div>

            <div className="mb-1">
              <label htmlFor="schools">Schools: &nbsp;</label>
              <input
                className="form-control"
                id="schools"
                size="80"
                placeholder="Schools that you have studied, taught..."
                value={this.state.schools}
                onChange={this.handleSchoolsChange}
              />
            </div>

            <div className="mb-1">
              <label htmlFor="books">Books: &nbsp;</label>
              <input
                className="form-control"
                id="books"
                size="80"
                placeholder="Schools that you have studied, taught..."
                value={this.state.books}
                onChange={this.handleBooksChange}
              />
            </div>

            <div className="mb-1">
              <label htmlFor="movies">Movies: &nbsp;</label>
              <input
                className="form-control"
                id="movies"
                size="80"
                placeholder="Movies that you have watched..."
                value={this.state.movies}
                onChange={this.handleMoviesChange}
              />
            </div>

            <div className="mb-1">
              <label htmlFor="music">Music: &nbsp;</label>
              <input
                className="form-control"
                id="music"
                size="80"
                placeholder="Music that you have listened..."
                value={this.state.music}
                onChange={this.handleMusicChange}
              />
            </div>

            <div className="mb-1">
              <label htmlFor="sports">Sports: &nbsp;</label>
              <input
                className="form-control"
                id="sports"
                size="80"
                placeholder="Sports that you are interested in..."
                value={this.state.sports}
                onChange={this.handleSportsChange}
              />
            </div>
          </div>
          <div className="mb-1 mt-1">
            <input
              id="AboutMeInputSubmitID"
              className="btn btn-primary mt-2"
              type="submit"
              value="Submit"
            />
          </div>
        </form>
        <MaxLiProduction />
      </div>
    );
  }
}
export default AboutMe;
