import React, { Component } from "react";

import challenge from "../images/ChallengingConventionalWisdomV2.png";
import bookmark_manager from "../images/bookmark-manager.svg";

import Password_manager from "../images/Password-manager.svg";

import money_bag_dollar from "../images/money-bag-dollar.svg";
import a_balancing_act from "../images/scale.svg";
import password from "../images/password.svg";
import social_media from "../images/social_media.svg";

import MaxLiProduction from "./MaxLiProduction";

import "./Home.css";
class Home extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="container-fluid my-2">
          <div className="text-center">
            <img
              src={challenge}
              width="70%"
              alt="challenging conventional wisdom is OK"
            />
          </div>
        </div>

        <div className="container-fluid my-4">
          <div className="row background-google-green">
            <div className="col">
              <div className="text-center display-3 my-1">
                <strong className="fluid-big-title">Share Wisely. Why?</strong>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 text-center">
              <p className="big150p p-2">
                <strong>A Balancing Act</strong>
              </p>
              <img
                className="img-responsive centered-img p-2"
                width="150px"
                src={a_balancing_act}
                alt="A balancing act"
              />
              <p className="card p-2 justifiedP">
                Some people share too much and some people share nothing. The
                right way to do is to share wisely. Sharing too much has
                security risks. Sharing nothing misses the opportunity to
                collborate among people. Have a right balance.
              </p>
            </div>
            <div className="col-md-4 text-center">
              <p className="p-2 big150p">
                <strong>Social Media Platform</strong>
              </p>
              <img
                className="img-fluid centered-img p-2"
                width="150px"
                src={social_media}
                alt="Social Media icon"
              />
              <p className="card p-2 justifiedP">
                Social medial platforms allow you to share any informaiton. Most
                shared information is casual. We are different. We focus on
                critical information that is commonly called online credentials,
                or credentials for short.
              </p>
            </div>
            <div className="col-md-4 text-center">
              <p className="big150p p-2">
                <strong>Password Manager</strong>
              </p>
              <img
                className="img-fluid centered-img p-2"
                width="150px"
                src={password}
                alt="an icon for password"
              />

              <p className="card p-2 justifiedP">
                Password managers are so focused on security that it largely
                ignores the fact that some credentials should be shared. For
                example, online credential for a family health insurance plan
                shared be shared among all family members. Some members may
                attend a college that is thousands of miles away.
              </p>
            </div>
          </div>

          <div className="row background-google-green">
            <div className="col">
              <div className="text-center display-3 my-1">
                <strong className="fluid-big-title">Store</strong>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 text-center">
              <p className="big150p p-2">
                <strong>Bookmark</strong>
              </p>
              <img
                className="img-responsive centered-img p-2"
                width="150px"
                src={bookmark_manager}
                alt="Bookmark Manager"
              />
              <p className="card p-2 justifiedP">
                You can enter the website and fill in only the fields you need.
                This creates a bookmark collection that you can keep for
                yourself or share with friends.
              </p>
            </div>
            <div className="col-md-4 text-center">
              <p className="p-2 big150p">
                <strong>Credential </strong>
              </p>
              <img
                className="img-fluid centered-img p-2"
                width="150px"
                src={Password_manager}
                alt="Hint Manager"
              />
              <p className="card p-2 justifiedP">
                By entering appname, username, hint and monthly charge, you
                manage all "credentials" in one place. Storing hints instead of
                plain password adds a layer of protection because there is no
                algorithm to compute passwords directly from password hints.
              </p>
            </div>
            <div className="col-md-4 text-center">
              <p className="big150p p-2">
                <strong>Spending</strong>
              </p>
              <img
                className="img-fluid centered-img p-2"
                width="150px"
                src={money_bag_dollar}
                alt="Money Bag Dollar Sign"
              />

              <p className="card p-2 justifiedP">
                The monthly charge feature allows you to manage all your online
                spending in one place. The system conveniently tells you the
                total amount of money that you spend online in a month.
              </p>
            </div>
          </div>
          <div className="row background-google-green">
            <div className="col">
              <div className="text-center display-3 my-1">
                <strong className="fluid-big-title">Share</strong>
              </div>
            </div>
          </div>
          <div className="row text-center">
            <div className="col-md-4 color-shadow color-white px-4 py-4">
              <p className="big150p my-2">
                <strong>Partial Sharing</strong>
              </p>
              <p>You can specify which trusted partner(s) to share with.</p>
            </div>
            <div className="col-md-4 color-white bg-color-google-blue px-4 py-4">
              <p className="big150p my-2">
                <strong>Access Point</strong>
              </p>
              <p>
                An access point allows you to specify credentials for public
                access.
              </p>
            </div>
            <div className="col-md-4 color-shadow color-white px-4 py-4">
              <p className="big150p my-2">
                <strong>Marketplace</strong>
              </p>
              <p>
                You can give away your credentials, or ask for other users'
                credentials. You have full control.
              </p>
            </div>
          </div>

          <MaxLiProduction />
        </div>
      </React.Fragment>
    );
  }
}

export default Home;
