import React, { Component } from "react";
import MaxLiProduction from "./MaxLiProduction";

class Contact extends Component {
  handleOnlineFormSubmit = async (event) => {
    event.preventDefault();
    const contactName = document.getElementById("contact-name");
    const contactPhone = document.getElementById("contact-phone");
    const contactEmail = document.getElementById("contact-email");
    const contactMessage = document.getElementById("contact-message");

    fetch(
      "https://9ftfirq6gf.execute-api.us-east-1.amazonaws.com/prod/messages",
      {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          name: contactName.value,
          phone: contactPhone.value,
          email: contactEmail.value,
          message: contactMessage.value,
        }),
      }
    ).then(
      function (res) {
        contactName.value = "";
        contactPhone.value = "";
        contactEmail.value = "";
        contactMessage.value = "";
        alert("Message sent successfully!");
      },
      function (err) {
        alert("Our apologies, we were unable to send your message.");
      }
    );
  };
  render() {
    return (
      <div className="container-fluid my-4">
        <h2>GOT QUESTIONS? We have solutions!</h2>
        <div>
          The easiest way to contact us is to
          <a href="mailto: max.li@live.com"> Send email to:</a>
          <p>Max Li,PhD</p>
          <p> Cell Phone: 615-208-3675</p>
          <p>Physical Address: 5 Dufton Rd, Andover, MA 01810 USA</p>
          <iframe
            title="Physical address of ShareWisely.us"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2933.6297346436922!2d-71.14006491820173!3d42.66920117297541!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e30881e4322dd9%3A0xd1ff87a6d10851c3!2s5%20Dufton%20Rd%2C%20Andover%2C%20MA%2001810!5e0!3m2!1sen!2sus!4v1594674301273!5m2!1sen!2sus"
            width="600"
            height="450"
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen=""
          ></iframe>
        </div>

        <h2 hidden className="mt-5">
          Contact Us Through Online Form(Experimental)
        </h2>
        <form hidden onSubmit={this.handleOnlineFormSubmit}>
          <div className="form-group">
            <div>
              <label htmlFor="contact-name">Name</label>
              <input
                className="form-control"
                type="text"
                id="contact-name"
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="contact-phone">Phone</label>
              <input
                className="form-control"
                type="tel"
                id="contact-phone"
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="contact-email">Email</label>
              <input
                className="form-control"
                type="email"
                id="contact-email"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="contact-message">Message</label>
              <textarea
                className="form-control"
                id="contact-message"
                required
              ></textarea>
              <button type="submit" className="mt-5 btn btn-primary">
                Send Message
              </button>
            </div>
          </div>
        </form>

        <MaxLiProduction />
      </div>
    );
  }
}

export default Contact;
